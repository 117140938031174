import React, { useEffect } from "react";
import PriceCard from "../../Components/PriceCard/idex";
import { Box } from "@mui/material";
//import Notified from "../../Components/Notified";
//import ColdCalling from "../../Components/ColdCalliing";
//import FirstWaveAi from "../../Components/FirstWaveAi";
//import Question from "../../Components/Question";
import PriceTable from "../../Components/PriceTable";

const HeroSales = () => {
	const plans = [
		{
			title: "Free",
			price: "$0",
			description: "Free Features.",
			features: [
				"Secure Managed Environment",
				"Users forward their calls to Customer Sidekick",
				"Receive a set of pre-identified Q&As",
			],
		},
		{
			title: "Basic plan",
			price: "$200",
			description: "Basic features.",
			features: [
				"Receive a set of pre-identified Q&As",
				"Basic customization for Q&A",
				"Monthly Performance Reports",
			],
		},
		{
			title: "Advanced plan",
			price: "$700",
			description: "Advanced features.",
			features: [
				"More customization options for Q&A",
				"Enhanced support and features",
				"Integration with CRM System",
			],
		},
		{
			title: "Professional plan",
			price: "$1000",
			description: "Professional features.",
			features: [
				"Full customization options for Q&A",
				"Premium support and features",
				"Caps around talk time",
			],
		},
	];
	const headings = {
		title: "Customer Sidekick",
		heading: "Customer Sidekick Plans",
		description: "Simple, transparent pricing that grows with you.",
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Box sx={{ display: "flex", flexDirection: "column" }}>
			<PriceCard plans={plans} headings={headings} />
			<PriceTable />
		</Box>
	);
};

export default HeroSales;
