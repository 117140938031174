import React, { useEffect, useState } from "react";
import FirstWaveAi from "../../Components/FirstWaveAi";
import Notified from "../../Components/Notified";
//import ColdCalling from "../../Components/ColdCalliing";
import { Box } from "@mui/material";
import StillHaveQuestion from "../../Components/StillHaveQuestion";
import FaqsCard from "../../Components/FaqsCard";
import HeaderWithSearch from "../../Components/HeaderWithSearch";

export default function FaqsPage() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [searchValue, setSearchValue] = useState("");
	const headerData = {
		pageName: "FAQ",
		title: "Frequently Asked Questions",
		description:
			"Everything you need to know about the product and billing. Can’t find the answer you’re looking for? Please chat to our friendly team.",
		setSearchValue: setSearchValue,
	};
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				// justifyContent: 'center',
			}}
		>
			<HeaderWithSearch headerData={headerData} />
			<FaqsCard searchValue={searchValue} />
			<StillHaveQuestion />
			{/* <ColdCalling /> */}
			<Notified />
			<FirstWaveAi />
		</Box>
	);
}
