import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import FirstWaveAi from "../../Components/FirstWaveAi";
import Notified from "../../Components/Notified";
import ColdCalling from "../../Components/ColdCalliing";
import HowPurchase from "../../Components/HowParchase";
import ConsistentlyAuthority from "../../Components/ConsistentlyAuthority";
//import HeaderWithButton from "../../Components/HeaderWithButton";
//import SuccessStory from "../../Components/SuccessStory";
//import PriceCard from "../../Components/PriceCard/idex";
import PotentialSales from "../../Components/PontentialSales";
import ContactCard from "../../Components/ContactCard";
import GetStartedCard from "../../Components/GetStartedCard";
import ServicesHelp from "../../Components/ServicesHelp";
import ProductQuestions from "../../Components/ProductQuestions";
import SaleHeroHeadersearch from "../../Components/saleHeroHeadersearch";

function ProductSalesHero() {
	const cardData = [
		{
			cardImg: "../../asets/images/smileIcon.png",
			title: "Dashboard Access",
			description:
				"The intuitive dashboard provides a centralized hub where users can easily navigate through all the features of Sales Hero.",
			link: "",

			icon: "../../asets/images/smileIcon.png",
		},
		{
			cardImg: "",
			title: "Campaign Budgeting ",
			description:
				"Sales Hero allows you to define and adjust the budget for your campaigns, giving you full control over your spending.",
			link: "",
			icon: "",
		},
		{
			cardImg: "",
			title: "Language Customisation",
			description:
				"Experiment with different scripts to determine which messaging resonates best with your audience.",
			link: "",
			icon: "",
		},
		{
			cardImg: "",
			title: "Data integration",
			description:
				"Seamlessly import your contact lists by uploading them directly to the system or integrating with third-party databases like Zoom Info.",
			link: "",
			icon: "",
		},
		{
			cardImg: "",
			title: "Automated Calling",
			description:
				"Let the AI take over the tedious task of making cold calls. Sales Hero's automated calling feature ensures consistent and efficient outreach.",
			link: "",
			icon: "",
		},
		{
			cardImg: "",
			title: "Lead Data Collection",
			description:
				"The AI conducts calls, it collects valuable data from leads who express interest. This information is meticulously organized.",
			link: "",
			icon: "",
		},
		{
			cardImg: "",
			title: "CRM integration",
			description:
				"Sales Hero integrates with your CRM, ensuring that all collected lead data is automatically transferred and organized within your existing system.",
			link: "",
			icon: "",
		},
		{
			cardImg: "",
			title: "Metrics and Analytics",
			description:
				"Gain insights into your campaign’s effectiveness with comprehensive metrics and analytics. Track conversion rates, call outcomes, and other critical indicators.",
			link: "",
			icon: "",
		},
		{
			cardImg: "",
			title: "Real-Time Monitoring",
			description:
				"Monitor your campaigns in real-time to see how they are performing. This feature allows you to make immediate adjustments based on live data.",
			link: "",
			icon: "",
		},
		{
			cardImg: "",
			title: "Scalability",
			description:
				"Sales Hero integrates with your CRM, ensuring that all collected lead data is automatically transferred and organized within your existing system.",
			link: "",
			icon: "",
		},
		{
			cardImg: "",
			title: "Customizable Scripts",
			description:
				"Gain insights into your campaign’s effectiveness with comprehensive metrics and analytics. Track conversion rates, call outcomes, and other critical indicators.",
			link: "",
			icon: "",
		},
		{
			cardImg: "",
			title: "User Permissions",
			description:
				"Monitor your campaigns in real-time to see how they are performing. This feature allows you to make immediate adjustments based on live dat",
			link: "",
			icon: "",
		},
		{
			cardImg: "",
			title: "Customer Support",
			description:
				"Sales Hero integrates with your CRM, ensuring that all collected lead data is automatically transferred and organized within your existing system.",
			link: "",
			icon: "",
		},
		{
			cardImg: "",
			title: "Compliance",
			description:
				"Gain insights into your campaign’s effectiveness with comprehensive metrics and analytics. Track conversion rates, call outcomes, and other critical indicators.",
			link: "",
			icon: "",
		},
	];
	const headerData = {
		pageName: "Products",
		title: "Sales Hero",
		description:
			"Sales Hero is your ultimate AI-powered sales assistant, designed to optimize your sales process and increase conversions.",
		buttonText: "View Pricing",
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				// alignItems: 'center',
				width: "100%",
			}}
		>
			<SaleHeroHeadersearch headerData={headerData} />
			<PotentialSales />
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					mt: { md: "96px", xs: "64px" },
				}}
			>
				<Box
					sx={{
						width: { md: "85%", xs: "95p%", sm: "90%" },
					}}
				>
					<ServicesHelp />
					<Grid container spacing={4}>
						{cardData.map((card, index) => {
							return (
								<Grid
									item
									xs={12}
									md={2}
									lg={3}
									sx={{
										maxWidth: "100% !important",
										minWidth: "30% !important",
									}}
									key={index}
								>
									<ContactCard card={card} />
								</Grid>
							);
						})}
						<Grid
							sx={{
								maxWidth: "100% !important",
								minWidth: "30% !important",
							}}
							item
							xs={12}
							md={2}
							lg={3}
						>
							<GetStartedCard />
						</Grid>
					</Grid>
				</Box>
			</Box>

			<Box sx={{ mt: { md: "96px", xs: "64px" }, width: "100%" }}>
				<HowPurchase />
				<ConsistentlyAuthority />
				{/* <SuccessStory /> */}
				<ColdCalling />
				<Notified />
				<FirstWaveAi />
			</Box>
		</Box>
	);
}

export default ProductSalesHero;
