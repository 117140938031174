import { Box, Button, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../context/ThemeContext";

export default function HeaderWithTwoButtons({ headerData }) {
  const navigate = useNavigate();
  const { darkMode } = useContext(ThemeContext);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: darkMode ? "#121212" : "#E3EFFD",
        paddingTop: { md: "190px", xs: "100px" },
        paddingBottom: { md: "110px", xs: "64px" },
        width: "100%",
      }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "left", md: "center" },
          textAlign: { xs: "left", md: "center" },
          width: { md: "54%", lg: "54%", xl: "54%", sm: "90%", xs: "95%" },
        }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "24px",
            color: darkMode ? "#BB86FC" : "#0F65C7",
            mb: "12px",
          }}>
          {headerData.pageName}
        </Typography>
        <Typography
          sx={{
            fontSize: "52px",
            fontWeight: "800",
            lineHeight: "62px",
            color: darkMode ? "#FFFFFF" : "#000000",
          }}>
          {headerData.title}
        </Typography>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "30px",
            color: darkMode ? "#B0B0B0" : "#666666",
            mt: "16px",
            letterSpacing: "-0.25px",
          }}>
          {headerData.description}
        </Typography>
        <Box
          sx={{
            mt: { xs: "32px", md: "48px" },
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            width: { md: "auto", xs: "100%" },
            justifyContent: { md: "center" },
            gap: { md: 2, xs: 1 }, // Use gap for spacing between buttons
          }}>
          <Button
            onClick={() => navigate("/contact-us")}
            variant="contained"
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: "24px",
              textTransform: "none",
              height: "48px",
              minWidth: "auto", // Ensure button width fits the content
              padding: "0 16px", // Adjust padding for better fit
            }}
            style={{
              backgroundColor: darkMode ? "#1F1F1F" : "#FFFFFF",
              color: darkMode ? "#E0E0E0" : "#344054",
            }}>
            {headerData.button1}
          </Button>

          <Button
            onClick={() => navigate("/price")}
            variant="contained"
            sx={{
              height: "48px",
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: "24px",
              textTransform: "none",
              minWidth: "137px", // Set a minimum width for the button
            }}
            style={{
              backgroundColor: darkMode ? "#3700B3" : "#0F65C7",
              color: "#FFFFFF",
            }}>
            {headerData.button2}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
