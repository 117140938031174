import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import Notified from '../../Components/Notified';
import React, { useEffect, useState } from 'react';
import FirstWaveAi from '../../Components/FirstWaveAi';
import { IndustryNicheHeader } from '../../Components/IndustryNiche';
import { IndustryNicheSection } from '../../Sections/IndustryNiche';

const data = [
  {
    header: "Real Estate Industry",
    header_description: "Never miss a prospective client with First Wave AI’s suite of phone tools.",
    article_title: "Answer calls, collect customer info, and forward SMS texts for followup",
    article_body: "First Wave AI has a suite of tools that are perfect for Real Estate."
  },
  {
    header: "Modern Healthcare Sector",
    header_description: "Enhance patient engagement with MedTech Solutions’ cutting-edge digital tools.",
    article_title: "Streamline appointments, manage patient records, and automate follow-up reminders",
    article_body: "MedTech Solutions offers innovative tools designed to improve patient management and streamline healthcare operations."
  },
  {
    header: "Financial Services and Banking",
    header_description: "Boost customer satisfaction with FinTech Innovations’ advanced software solutions.",
    article_title: "Simplify transactions, track financial goals, and provide real-time support",
    article_body: "FinTech Innovations provides software that enhances financial services, making transactions easier and customer support more effective."
  },
  {
    header: "Educational Technology",
    header_description: "Transform learning experiences with EduTech Pro’s interactive platforms.",
    article_title: "Facilitate remote learning, track student progress, and enhance engagement",
    article_body: "EduTech Pro’s platforms offer tools for remote education that help educators and students stay connected and engaged."
  },
  {
    header: "E-commerce and Retail",
    header_description: "Increase sales with ShopSmart’s innovative online marketing tools.",
    article_title: "Optimize online ads, manage customer feedback, and boost sales performance",
    article_body: "ShopSmart delivers powerful tools for e-commerce businesses to enhance their online presence and drive sales."
  },
  {
    header: "Hospitality and Travel",
    header_description: "Elevate guest experiences with TravelTech’s integrated booking systems.",
    article_title: "Streamline reservations, manage guest preferences, and offer personalized recommendations",
    article_body: "TravelTech’s booking systems are designed to provide a seamless experience for both travelers and hospitality providers."
  }
];

const IndustryNiche = () => {
  const { article_header } = useParams();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [article, setArticle] = useState(null);

  useEffect(() => {
    const foundArticle = data.find(article => article.header.includes(article_header));
    setArticle(foundArticle || null);
    setIsPageLoading(false);
  }, [article_header]);

  if (isPageLoading === true) return <div>Loading ...</div>

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {article && <IndustryNicheHeader article={article} />}
      {article && <IndustryNicheSection article={article} />}
      
      <Notified />
      <FirstWaveAi />
    </Box>
  )
}

export default IndustryNiche
