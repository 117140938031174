import { Box, Grid, Button, Typography } from "@mui/material";

import React from "react";
import { useNavigate } from "react-router-dom";

export default function ConsistentlyAuthority() {
	const navigate = useNavigate();
	return (
		<Box
			sx={{
				mt: "64px",
				display: "flex",
				justifyContent: "center",
			}}
		>
			<Box
				sx={{
					width: {
						// height: '632px',
						md: "90%",
						xs: "95%",
					},
					boxShadow: "rgba(234, 236, 240, 1) 0px 2px 8px 0px",
				}}
			>
				<Box
					sx={{
						padding: { md: "32px", xs: "32px 24px" },
						justifyContent: "center",
					}}
				>
					<Grid
						item
						sx={{
							display: "flex",
							alignItems: "center",
						}}
						xs={12}
						md={6}
					>
						<Box
							sx={{
								display: "flex:",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
								maxWidth: "550px",
							}}
							mx={"auto"}
						>
							{" "}
							<Typography
								sx={{
									//styleName: Display xs/Semibold;
									// font-family: Inter;
									fontSize: "24px",
									fontWeight: "600",
									lineHeight: "32px",
								}}
							>
								You're consistently the one with authority.
							</Typography>
							<Typography
								sx={{
									mt: "4px",
									//styleName: Display xs/Semibold;
									// font-family: Inter;
									fontSize: "16px",
									fontWeight: "400",
									lineHeight: "24px",
								}}
							>
								You establish your budget and bidding parameters. We guarantee
								that we won't exceed your maximum spending limit.
							</Typography>
							<Typography
								sx={{
									mt: "4px",
									//styleName: Display xs/Semibold;
									// font-family: Inter;
									fontSize: "16px",
									fontWeight: "400",
									lineHeight: "24px",
								}}
							>
								Set a dollar amount, set the target demographic by importing
								your contact list, adjust the sales scripts, the dashboard
								informs the user how many people they can reach, and start.
							</Typography>
							<Button
								onClick={() => navigate("/signup")}
								style={{
									width: "100%",
									textTransform: "none",
									backgroundColor: "rgba(15, 101, 199, 1)",
									marginTop: "32px",
									color: "rgba(255, 255, 255, 1)",
								}}
							>
								Get started
							</Button>
						</Box>
					</Grid>
				</Box>
			</Box>
		</Box>
	);
}
