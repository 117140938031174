import { Box, Grid, Typography, Divider } from "@mui/material";
import React from "react";

function GettingStarted() {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				width: "100%",
				p: 3, // Adds padding around the entire container
			}}
		>
			<Box
				sx={{
					width: { md: "85%", lg: "85%", xl: "85%", sm: "90%", xs: "95%" },
					padding: "24px", // Adds padding inside the box to space out content from the borders
				}}
			>
				<img
					style={{ width: "100%", marginBottom: "20px" }} // Ensures space below the image
					src={require("../../asets/images/boyAndGirlAbout.png")}
					alt="About Us"
				/>
				<Typography
					sx={{
						display: "flex",
						alignSelf: "flex-start",
						fontSize: "16px",
						fontWeight: "600",
						lineHeight: "24px",
						color: "#0F65C7",
						textAlign: { xs: "left", md: "center" },
						mb: "12px",
						mt: "24px",
					}}
				>
					Our story
				</Typography>
				<Typography
					sx={{
						fontSize: "24px",
						fontWeight: "700",
						lineHeight: "36px",
						letterSpacing: "-0.25px",
						mb: "20px",
					}}
				>
					We dream of a better future where AI helps humans solve problems. Our
					focus is on phone-based AI tools.
				</Typography>
				<Grid
					container
					rowSpacing={4}
					columnSpacing={{ xs: 1, sm: 2, md: 3 }}
					sx={{ mt: { md: "64px", xs: "48px" } }}
				>
					<Grid item xs={12} md={6}>
						<Typography
							sx={{
								fontSize: { md: "18px", xs: "18px" },
								fontWeight: "400",
								lineHeight: { md: "28px", xs: "24px" },
								letterSpacing: "-0.25px",
								mb: "20px",
							}}
						>
							A few months ago, Philip had to call his bank’s customer support
							line and had to deal with an Interactive Voice Response (IVR)
							robot that didn’t understand anything he said. It routed him to
							the wrong departments, wasting two hours of his life. Later, he
							had to call his airline about baggage that went missing in San
							Francisco while he was travelling to Hong Kong. He got four
							different answers from four different people. Even later, he
							called his hospital about an incorrect bill and stayed on hold for
							over an hour, only to have the debt cleared because it was
							obviously wrong. Customer support is broken when it should be so
							much better. His experience is sadly very common nowadays. But the
							good part is we can build a better future! Thanks to advances in
							AI we can build solutions to those same problems above.
						</Typography>
						<Divider sx={{ my: 3 }} />
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography
							sx={{
								mt: { xs: "15px", md: "0px" },
								fontSize: { md: "18px", xs: "18px" },
								fontWeight: "400",
								lineHeight: { md: "28px", xs: "24px" },
								letterSpacing: "-0.25px",
								mb: "20px",
							}}
						>
							First Wave AI leverages multiple recent innovations to make a
							simple platform with a suite of tools that fix these problems!
						</Typography>
						<Divider sx={{ my: 3 }} />
						<Typography
							sx={{
								fontSize: { md: "18px", xs: "18px" },
								fontWeight: "400",
								lineHeight: { md: "28px", xs: "24px" },
								letterSpacing: "-0.25px",
								mb: "20px",
							}}
						>
							<ul style={{ paddingLeft: "20px", marginTop: "15px" }}>
								<li>
									Any language, any time: 24/7 availability means no more
									waiting until 9am Pacific Time Zone to call someplace, or
									missing a 5pm Eastern Time Zone cutoff.
								</li>
								<li>
									Infinitely scalable: No more waiting in queue for an agent
									when AI can handle unlimited in/outbound calls.
								</li>
								<li>
									100% correct answers: No more guessing at the answer. You can
									define all the answers exactly as they’re given to customers.
								</li>
								<li>
									Adaptable conversations: No more IVR. Your customers can now
									ask the question they need without navigating a troublesome
									phone-tree.
								</li>
							</ul>
						</Typography>
						<Divider sx={{ my: 3 }} />
						<Typography
							sx={{
								fontSize: { md: "18px", xs: "18px" },
								fontWeight: "400",
								lineHeight: { md: "28px", xs: "24px" },
								letterSpacing: "-0.25px",
								mb: "20px",
							}}
						>
							Happy customers will lead to more revenue. We’ll help you collect
							data and qualify leads while making the experience enjoyable for
							your potential customers.
						</Typography>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}

export default GettingStarted;
