import React, { useContext } from "react";
import { Card, CardContent, Typography, Button } from "@mui/material";
import { ThemeContext } from "../../context/ThemeContext";
import { useNavigate } from "react-router-dom";

const GetStartedCard = () => {
	const navigate = useNavigate();
	const { darkMode } = useContext(ThemeContext);

	return (
		<Card
			sx={{
				backgroundColor: darkMode ? "#1976d2" : "#1976d2",
				color: "#fff",
				borderRadius: "8px",
				padding: "16px",
				maxWidth: "100%",
				height: "100%", // Ensure the card takes full height
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between", // Ensure content is evenly distributed
			}}
		>
			<CardContent sx={{ textAlign: "center" }}>
				<Typography
					variant="h6"
					sx={{
						fontSize: "20px",
						fontWeight: "600",
						lineHeight: "30px",
						color: "#FFFFFF",
					}}
					gutterBottom
				>
					Get Started Now
				</Typography>
				<Typography
					sx={{
						fontSize: "16px",
						fontWeight: "400",
						lineHeight: "24px",
						color: "#FFFFFF",
						mt: "8px",
					}}
					variant="body2"
					paragraph
				>
					Take your business to greater heights! Have any queries? Contact Us!
				</Typography>

				<Button
					variant="contained"
					onClick={() => navigate("/signup")}
					style={{
						backgroundColor: darkMode ? "#555" : "#E3EFFD",
						textTransform: "none",
						color: darkMode ? "#fff" : "#093F7C",
						marginTop: "16px",
					}}
					sx={{
						width: {
							md: "186px",
							xs: "100%",
						},
						fontSize: "16px",
						fontWeight: "600",
						lineHeight: "30px",
					}}
				>
					Get Started Today!
				</Button>
			</CardContent>
		</Card>
	);
};

export default GetStartedCard;
