import React, { useState, useContext } from "react";
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../context/ThemeContext";

const Question = () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const { darkMode } = useContext(ThemeContext);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: darkMode ? "#1a1a1a" : "rgba(249, 250, 251, 1)",
        paddingTop: { md: "96px", xs: "64px" },
        paddingBottom: { md: "96px", xs: "64px" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: { md: "20px", xs: "10px" }, // Adjusted margin-top to fix overlapping issue
      }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: {
            md: "85%",
            xs: "95%",
            sm: "90%",
            lg: "60%",
          },
        }}>
        <Typography
          variant="h4"
          sx={{
            fontSize: "36px",
            fontWeight: "600",
            lineHeight: "44px",
            letterSpacing: "-0.02em",
            color: darkMode ? "#ffffff" : "rgba(16, 24, 40, 1)",
          }}
          align="center">
          Frequently asked questions
        </Typography>
        <Typography
          align="center"
          sx={{
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "30px",
            letterSpacing: "-0.02em",
            color: darkMode ? "#cccccc" : "rgba(71, 84, 103, 1)",
            mt: { md: "20px", xs: "16px" },
            mb: { md: "64px", xs: "48px" },
          }}>
          Everything you need to know about the product and billing.
        </Typography>
        <div>
          {[
            {
              question: "Is there a free trial available?",
              answer:
                "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
            },
            {
              question: "Can I change my plan later?",
              answer:
                "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
            },
            {
              question: "What is your cancellation policy?",
              answer:
                "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
            },
            {
              question: "Can other info be added to an invoice?",
              answer:
                "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
            },
            {
              question: "How does billing work?",
              answer:
                "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
            },
            {
              question: "How do I change my account email?",
              answer:
                "Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
            },
          ].map((item, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              sx={{
                marginBottom: "10px",
                boxShadow: "none",
                borderRadius: "8px",
                backgroundColor: darkMode
                  ? "#333333"
                  : "rgba(249, 250, 251, 1)",
              }}>
              <AccordionSummary
                expandIcon={
                  expanded === `panel${index}` ? (
                    <RemoveIcon
                      sx={{ color: darkMode ? "#ffffff" : "#475467" }}
                    />
                  ) : (
                    <AddIcon sx={{ color: darkMode ? "#ffffff" : "#475467" }} />
                  )
                }
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    lineHeight: "28px",
                    color: darkMode ? "#ffffff" : "rgba(16, 24, 40, 1)",
                  }}>
                  {item.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  fontSize: "16px",
                  fontWeight: "300",
                  lineHeight: "24px",
                  color: darkMode ? "#cccccc" : "rgba(71, 84, 103, 1)",
                }}>
                <Typography>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          width: {
            md: "87%",
            xs: "95%",
            sm: "90%",
            lg: "87%",
          },
          marginTop: "40px",
          backgroundColor: darkMode ? "#1a1a1a" : "#ffffff",
          borderRadius: "8px",
          p: "40px 0px",
        }}>
        <img
          style={{ maxWidth: "150px" }}
          src={require("../../asets/images/groupAvatar.png")}
          alt="group avatar"
        />
        <Typography
          variant="h6"
          align="center"
          sx={{
            mt: { md: "32px", xs: "24px" },
            color: darkMode ? "#ffffff" : "rgba(16, 24, 40, 1)",
            fontSize: "20px",
            fontWeight: "600",
            lineHeight: "30px",
          }}>
          Still have questions?
        </Typography>
        <Typography
          variant="body2"
          align="center"
          sx={{
            color: darkMode ? "#cccccc" : "rgba(71, 84, 103, 1)",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "26px",
            mt: "8px",
          }}>
          Can’t find the answer you’re looking for? Please chat to our friendly
          team.
        </Typography>
        <Button
          onClick={() => navigate("/contact-us")}
          variant="contained"
          sx={{
            mt: { xs: "24px", md: "32px" },
            textTransform: "none",
            width: "134px",
          }}
          color="primary">
          Get in touch
        </Button>
      </Box>
    </Box>
  );
};

export default Question;
