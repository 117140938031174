import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import { ThemeContext } from "../../context/ThemeContext";

export const IndustryNicheSection = ({ article }) => {
	const { darkMode } = useContext(ThemeContext);

	return (
        <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", backgroundColor: darkMode ? "#121212" : "#FFFFFF", paddingTop: { md: "120px", xs: "60px" }, paddingBottom: { md: "30px", xs: "15px" } }} >
            <Box sx={{ display: "flex", flexDirection: "column", textAlign: { xs: "left", md: "center" }, width: { md: "54%", lg: "54%", xl: "54%", sm: "90%", xs: "95%" } }} >
                <Typography sx={{ fontSize: "40px", fontWeight: "600", lineHeight: "62px", color: darkMode ? "#ffffff" : "#000000" }} >
                    { article.article_title }
                </Typography>

                <Typography sx={{ fontSize: "20px", fontWeight: "400", lineHeight: "30px", color: darkMode ? "#B0B0B0" : "#666666", mt: "16px", letterSpacing: "-0.25px"}}>
                    { article.article_body }
                </Typography>
            </Box>
        </Box>
	);
}
