import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {
	createUserWithEmailAndPassword,
	getAuth,
	signInWithEmailAndPassword,
} from "firebase/auth";

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: "first-wave-ai",
	storageBucket: "first-wave-ai.appspot.com",
	messagingSenderId: "1086398872833",
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: "G-GMYNJLC6FQ",
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);
export const auth = getAuth(app);

export async function signup(email, password) {
	try {
		await createUserWithEmailAndPassword(auth, email, password);
		return auth.currentUser?.uid;
	} catch (error) {
		console.error("Error while signing user up", error);
		return error;
	}
}

export async function signin(email, password) {
	try {
		await signInWithEmailAndPassword(auth, email, password);
		return auth.currentUser?.uid;
	} catch (error) {
		console.error("Error while signing user in", error);
		return error;
	}
}
