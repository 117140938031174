import React, { useEffect } from "react";
import {
	Alert,
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Container,
	Grid,
	Link,
	TextField,
	Typography,
} from "@mui/material";
import { Google } from "@mui/icons-material";
import Notified from "../../Components/Notified";
import FirstWaveAi from "../../Components/FirstWaveAi";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, signup } from "../../services/firebase";
import { useNavigate } from "react-router-dom";
import { checkValue } from "../../helpers/validation";
import { ThemeContext } from "../../context/ThemeContext";

export const SignUp = () => {
	const navigation = useNavigate();
	const { darkMode } = React.useContext(ThemeContext);
	const [showAlert, setShowAlert] = React.useState(false);
	const [alertText, setAlertText] = React.useState("");
	const [isLoading, setIsLoading] = React.useState(false);
	const [dataValues, setDataValues] = React.useState({
		email: { value: "", error: false },
		password: { value: "", error: false },
		business: { value: "", error: false },
	});
	const emptyValues =
		Object.keys(dataValues).filter((key) => dataValues[key].value.length === 0)
			.length === 0;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	async function handleSignup(e) {
		e.preventDefault();
		try {
			if (!emptyValues) {
				setAlertText("There are some form values empty");
				setShowAlert(true);
				return;
			}

			setIsLoading(true);
			const userCreds = await signup(
				dataValues.email.value,
				dataValues.password.value
			);

			if (userCreds?.code && userCreds.code.includes("already-in-use")) {
				setDataValues({
					...dataValues,
					email: { ...dataValues.email, error: true },
				});
				setAlertText("Email already in use");
				setShowAlert(true);
				setIsLoading(false);
				return;
			}

			await fetch(`${process.env.REACT_APP_FWAI_API_URL}business`, {
				method: "POST",
				body: JSON.stringify({
					name: dataValues.business.value,
					uid: userCreds,
				}),
				headers: {
					"Content-Type": "application/json",
				},
			});

			setIsLoading(false);
			navigation("/signin");
		} catch (error) {
			console.error("Error signing up with google", error);
			setIsLoading(false);
		}
	}

	async function handleGoogleSignup() {
		const provider = new GoogleAuthProvider();
		try {
			const userCreds = await signInWithPopup(auth, provider);

			await fetch(`${process.env.REACT_APP_FWAI_API_URL}business`, {
				method: "POST",
				body: JSON.stringify({
					name: dataValues.business.value,
					uid: userCreds.user.reloadUserInfo.localId,
				}),
				headers: {
					"Content-Type": "application/json",
				},
			});
			navigation("/signin");
		} catch (error) {
			console.error("Error signing up with google", error);
		}
	}

	return (
		<>
			<Container component="main" maxWidth="xs">
				<Box
					sx={{
						marginTop: 8,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					{showAlert && (
						<Alert severity="warning" onClose={() => setShowAlert(false)}>
							{alertText}
						</Alert>
					)}
					<Backdrop
						sx={{
							color: darkMode ? "#303030" : "#f5f5f5",
							zIndex: (theme) => theme.zIndex.drawer + 1,
						}}
						open={isLoading}
					>
						<CircularProgress size={60} />
					</Backdrop>
					<Typography
						component="h1"
						variant="h5"
						sx={{ fontWeight: "bold", textAlign: "center", marginTop: 2 }}
					>
						Create an account
					</Typography>
					<Typography
						component="h2"
						variant="body2"
						sx={{ mt: 1, textAlign: "center" }}
					>
						Start your 30-day free trial.
					</Typography>
					<Box
						component="form"
						id="form"
						noValidate
						sx={{ mt: 3 }}
						onSubmit={(e) => handleSignup(e)}
					>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="name"
							label="Business Name"
							name="name"
							autoComplete="name"
							autoFocus
							error={dataValues.business.error}
							value={dataValues.business.value}
							onChange={(e) =>
								setDataValues({
									...dataValues,
									business: checkValue(e, "business"),
								})
							}
						/>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="email"
							label="Email"
							name="email"
							autoComplete="email"
							error={dataValues.email.error}
							value={dataValues.email.value}
							onChange={(e) =>
								setDataValues({ ...dataValues, email: checkValue(e, "email") })
							}
						/>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							name="password"
							label="Password"
							type="password"
							id="password"
							autoComplete="current-password"
							error={dataValues.password.error}
							value={dataValues.password.value}
							onChange={(e) =>
								setDataValues({
									...dataValues,
									password: checkValue(e, "password"),
								})
							}
						/>
						<Typography
							component="h3"
							variant="body2"
							sx={{ mt: 1, mb: 1, textAlign: "left", color: "gray" }}
						>
							<Box component="span" sx={{ display: "block" }}>
								Must be at least 8 characters
							</Box>
							<Box component="span" sx={{ display: "block" }}>
								Must contain one special character
							</Box>
						</Typography>
						<Button
							disabled={
								dataValues.email.error ||
								dataValues.password.error ||
								!emptyValues
							}
							type="submit"
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2, backgroundColor: "#1976d2", color: "#fff" }}
						>
							Get started
						</Button>
						<Button
							onClick={handleGoogleSignup}
							fullWidth
							variant="outlined"
							startIcon={<Google />}
							sx={{ mt: 1, mb: 2, backgroundColor: "#fff", color: "#757575" }}
						>
							Sign up with Google
						</Button>
						<Grid container justifyContent="center">
							<Grid item>
								<Link
									sx={{ cursor: "pointer" }}
									onClick={() => navigation("/signin")}
									variant="body2"
								>
									Already have an account? Log in
								</Link>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Container>

			<Notified />
			<FirstWaveAi />
		</>
	);
};

export default SignUp;
