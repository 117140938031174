import React, { useContext } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../context/ThemeContext";

export default function SaleHeroHeadersearch({ headerData }) {
	const navigate = useNavigate();
	const { darkMode } = useContext(ThemeContext);

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				backgroundColor: darkMode ? "#333333" : "#E3EFFD",
				paddingTop: { md: "190px", xs: "100px" },
				paddingBottom: { md: "110px", xs: "64px" },
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: { xs: "left", md: "center" },
					textAlign: { xs: "left", md: "center" },
					width: { md: "54%", lg: "54%", xl: "54%", sm: "90%", xs: "95%" },
				}}
			>
				<Typography
					sx={{
						fontSize: "16px",
						fontWeight: "600",
						lineHeight: "24px",
						color: darkMode ? "#ffffff" : "#0F65C7",
						mb: "12px",
					}}
				>
					{headerData.pageName}
				</Typography>
				<Typography
					sx={{
						fontSize: "52px",
						fontWeight: "800",
						lineHeight: "62px",
						color: darkMode ? "#ffffff" : "#000000", // Adjust text color for dark mode
					}}
				>
					{headerData.title}
				</Typography>
				<Typography
					sx={{
						fontSize: "20px",
						fontWeight: "400",
						lineHeight: "30px",
						color: darkMode ? "#cccccc" : "#666666",
						mt: "16px",
						letterSpacing: "-0.25px",
					}}
				>
					{headerData.description}
				</Typography>
				<Button
					onClick={() => navigate("/price")}
					variant="contained"
					sx={{
						marginTop: "60px",
						fontSize: "16px",
						fontWeight: "600",
						lineHeight: "24px",
						textTransform: "none",
						width: { md: "137px", xs: "100%" },
					}}
				>
					{headerData.buttonText}
				</Button>
			</Box>
		</Box>
	);
}
