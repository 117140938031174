export async function createUserBusiness(businessName, userId) {
	try {
		await fetch(`${process.env.REACT_APP_FWAI_API_URL}business`, {
			method: "POST",
			body: JSON.stringify({
				name: businessName,
				uid: userId,
			}),
			headers: {
				"Content-Type": "application/json",
			},
		});
		console.log("Business created");
	} catch (error) {
		console.error("Error while creating business", error);
	}
}

export async function getUserBusiness(userId) {
	try {
		const response = await fetch(
			`${process.env.REACT_APP_FWAI_API_URL}business/${userId}`,
			{
				method: "GET",
			}
		).then((r) => r.json());

		return !response.error ? true : false;
	} catch (error) {
		console.error("Error while getting business for user", error);
	}
}
