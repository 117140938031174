export function checkValue(e, type) {
	const value = e.target.value;

	if (value.length === 0) {
		return {
			value,
			error: true,
		};
	}

	const isPasswordValid =
		/^(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/.test(
			value
		);
	const isEmailValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
		value
	);

	if (type === "password" || type === "email") {
		return {
			value,
			error: type === "password" ? !isPasswordValid : !isEmailValid,
		};
	}

	return {
		value,
		error: false,
	};
}
