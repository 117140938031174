import React, { useContext } from "react";
import { Container, Grid, Typography, Button, Box } from "@mui/material";
import { ThemeContext } from "../../context/ThemeContext";
import { useNavigate } from "react-router-dom";

const BasicTasks = () => {
	const navigation = useNavigate();
	const { darkMode } = useContext(ThemeContext);

	const styles = {
		container: {
			padding: "32px",
		},
		title: {
			color: darkMode ? "#BB86FC" : "#1A237E",
			fontWeight: "bold",
		},
		description: {
			margin: "16px 0",
			color: darkMode ? "#B0B0B0" : "#424242",
		},
		buttonContainer: {
			marginTop: "16px",
		},
		image: {
			width: "100%",
			height: "auto",
		},
	};

	return (
		<Container style={styles.container}>
			<Grid
				container
				spacing={4}
				sx={{
					display: "flex",
					alignItems: "center",
				}}
			>
				<Grid item xs={12} lg={6}>
					<Typography
						variant="h4"
						sx={{
							display: "flex",
							alignSelf: "flex-start",
							fontSize: "16px",
							fontWeight: "600",
							lineHeight: "24px",
							color: darkMode ? "#BB86FC" : "#0F65C7",
							textAlign: { xs: "left" },
							mb: "12px",
						}}
					>
						Let AI handle basic tasks while you focus on complex customer
						service service
					</Typography>

					<Typography
						component="p"
						sx={{
							display: "inline",
							fontWeight: "400",
							fontSize: 36,
							lineHeight: { md: "44px", xs: "38px" },
							color: darkMode ? "#B0B0B0" : "#101828",
							mb: "12px",
						}}
					>
						Elevate Your Customer Service Experience with Customer Sidekick
					</Typography>

					<Typography
						sx={{
							fontSize: { md: "18px", xs: "16px" },
							fontWeight: "400",
							lineHeight: { md: "28px", xs: "24px" },
							color: darkMode ? "#B0B0B0" : "#475467",
						}}
					>
						Discover Customer Sidekick, the cutting-edge AI assistant that
						transforms your customer service operations. Designed for businesses
						of all sizes across industries like retail, legal, hospitality,
						healthcare, and more, Customer Sidekick seamlessly connects to your
						public-facing phone numbers on platforms like Google Maps and Yelp.
					</Typography>
					<Typography
						sx={{
							mt: "10px",
							fontSize: { md: "18px", xs: "16px" },
							fontWeight: "400",
							lineHeight: { md: "28px", xs: "24px" },
							color: darkMode ? "#B0B0B0" : "#475467",
						}}
					>
						When a customer calls, they are greeted by our intelligent AI
						Sidekick, armed with comprehensive business context and FAQs. This
						ensures efficient, accurate handling of customer inquiries, freeing
						up your team to focus on higher-value tasks.
					</Typography>
					<Typography
						sx={{
							mt: "10px",
							fontSize: { md: "18px", xs: "16px" },
							fontWeight: "400",
							lineHeight: { md: "28px", xs: "24px" },
							color: darkMode ? "#B0B0B0" : "#475467",
						}}
					>
						With features like automated call answering, customizable responses,
						and call logging, Customer Sidekick integrates effortlessly with CRM
						systems such as Salesforce, HubSpot, and Zendesk. Enjoy peace of
						mind with our robust security measures, including encryption,
						two-factor authentication, and GDPR compliance. We also perform
						daily data backups to safeguard your information.
					</Typography>
					<Typography
						sx={{
							mt: "10px",
							fontSize: { md: "18px", xs: "16px" },
							fontWeight: "400",
							lineHeight: { md: "28px", xs: "24px" },
							color: darkMode ? "#B0B0B0" : "#475467",
						}}
					>
						Choose from our flexible pricing plans, starting with a 14-day free
						trial, and enjoy ongoing support via email, phone, and live chat.
						Our detailed user guides and documentation make setup a breeze,
						helping you unlock the full potential of our AI customer service
						solution.
					</Typography>
					<Typography
						sx={{
							mt: "10px",
							fontSize: { md: "18px", xs: "16px" },
							fontWeight: "400",
							lineHeight: { md: "28px", xs: "24px" },
							color: darkMode ? "#B0B0B0" : "#475467",
						}}
					>
						Upgrade your customer service with Customer Sidekick – where AI
						meets excellence in every interaction.
					</Typography>

					<Box
						sx={{
							mt: { xs: "32px", md: "48px" },
							display: "flex",
							flexDirection: { md: "row", xs: "column" },
							width: { md: "291px", xs: "100%" },
							justifyContent: { md: "space-between" },
						}}
					>
						<Button
							variant="contained"
							onClick={() => navigation("/contact-us")}
							sx={{
								fontSize: "16px",
								fontWeight: "600",
								lineHeight: "24px",
								textTransform: "none",
								height: "48px",
								width: { md: "142px", xs: "100%" },
							}}
							style={{
								backgroundColor: darkMode ? "#1F1F1F" : "#FFFFFF",
								color: darkMode ? "#E0E0E0" : "#344054",
							}}
						>
							Get in touch
						</Button>

						<Button
							variant="contained"
							onClick={() => navigation("/about-us")}
							sx={{
								marginTop: { xs: "8px", md: "0px" },
								height: "48px",
								fontSize: "16px",
								fontWeight: "600",
								lineHeight: "24px",
								textTransform: "none",
								width: { md: "137px", xs: "100%" },
							}}
							style={{
								backgroundColor: darkMode ? "#3700B3" : "#0F65C7",
								color: "#FFFFFF",
							}}
						>
							Our process
						</Button>
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					lg={6}
					sx={{
						height: {
							md: "600px",
							xs: "400px",
						},
						maxHeight: "600px",
						minHeight: "240px",
					}}
				>
					<img
						style={{
							width: "100%",
							height: "100%",
							objectFit: "cover",
						}}
						src={require("../../asets/images/office.jpeg")}
						alt="Office"
					/>
				</Grid>
			</Grid>
		</Container>
	);
};

export default BasicTasks;
