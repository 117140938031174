import React, { useEffect, useContext } from "react";
import HomeHeader from "../../Components/HomeHeader";
import { Box } from "@mui/material";
// import OfficialPartners from "../../Components/OfficialPartners";
import FirstWaveAi from "../../Components/FirstWaveAi";
// import Notified from "../../Components/Notified";
// import MentionPress from "../../Components/MentionPress";
import FaqsHome from "../../Components/FaqsHome";
import CustomerJourneysSales from "../../Components/CustomerJourneysSales";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import TvIcon from "@mui/icons-material/Tv";
import RedeemOutlinedIcon from "@mui/icons-material/RedeemOutlined";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import robot8 from "../../asets/images/robot8.png";
// import SupportIcon from "@mui/icons-material/Support";
// import BuildIcon from "@mui/icons-material/Build";
import GraphSideKik from "../../Sections/GraphSideKik";
import SalesHeroSlide from "../../Sections/SalesHeroslide";
import { Features } from "../../Sections/Features";
import { ThemeContext } from "../../context/ThemeContext";

export default function Home() {
	const { darkMode } = useContext(ThemeContext);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const journeysForSales = {
		heading: "Customer Journeys for sales",
		description:
			"Sales Hero is your ultimate AI-powered sales assistant, designed to optimize your sales process and increase conversions.",
		buttonText: "Setting Up a Cold Call Campaign",
		settingOptions: [
			"Adjusting an Ongoing Campaign",
			"Analyzing Campaign Performance",
			"Integrating External Data Sources",
			"Managing Multiple Campaigns",
		],
		pic: robot8,
		features: [
			{
				icon: <PeopleAltOutlinedIcon sx={{ color: "#7F56D9" }} />,
				text: "Sign Up and Login",
				color: "#7F56D9",
			},
			{
				icon: <TvIcon sx={{ color: "#C37E5E" }} />,
				text: "Dashboard Navigation",
				color: "#C37E5E",
			},
			{
				icon: <RedeemOutlinedIcon sx={{ color: "#3D9060" }} />,
				text: "Budget Selection",
				color: "#3D9060",
			},
			{
				icon: <RedeemOutlinedIcon sx={{ color: "#2F67CA" }} />,
				text: "Language Customization",
				color: "#2F67CA",
			},
			{
				icon: <SettingsApplicationsIcon sx={{ color: "#D14949" }} />,
				text: "A/B Testing Setup",
				color: "#D14949",
			},
		],
	};

	const TextContent = {
		title: "Customer Sidekick",
		description:
			"The always on, always right customer support line. Respond to every customer call quickly, efficiently, and accurately to move customers toward revenue generation.",
		elements: [
			"Our platform ensures answers are 100% correct every time",
			"Each call is more efficient as our platform identifies “unknown” questions so you can provide answers",
			"Resolve customers as soon as possible before speaking with a human",
		],
	};

	const SalesContent = {
		title: "Sales Hero",
		description:
			"The ultimate AI-powered sales assistant, designed to optimize your sales process and increase conversions.",
		elements: [
			"Integrated with leading customer database software tools to better automate sales campaigns",
			"Track successful conversions and KPIs",
			"A/B test scripts at massive scale to increase conversion",
		],
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				backgroundColor: darkMode ? "#0C111D" : "#FFFFFF",
				color: darkMode ? "#f5f5f5" : "#000000",
				maxWidth: "1440px", // Set maximum width for the content
				margin: "0 auto", // Center the content horizontally
				// px: 2, // Add horizontal padding
			}}
		>
			<HomeHeader />
			{/* <OfficialPartners /> */}
			<Features />
			<SalesHeroSlide TextContent={SalesContent} />
			<CustomerJourneysSales journeysForSales={journeysForSales} />
			<GraphSideKik TextContent={TextContent} />
			{/* <MentionPress /> */}
			<FaqsHome />
			{/* <Notified /> */}
			<FirstWaveAi />
		</Box>
	);
}
