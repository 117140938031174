import { Box, Button, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../context/ThemeContext";

function ColdCalling() {
  const navigate = useNavigate();
  const { darkMode } = useContext(ThemeContext);

  return (
    <Box
      sx={{
        width: "100%",
        mt: "96px",
        display: "flex",
        justifyContent: "center",
        backgroundColor: darkMode ? "#121212" : "rgba(249, 250, 251, 1)",
        pb: "64px", // Adjust padding bottom for dark mode if needed
      }}>
      <Box
        sx={{
          width: {
            md: "85%",
            xs: "95%",
            sm: "90%",
          },
          backgroundColor: darkMode ? "#121212" : "rgba(249, 250, 251, 1)",
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          flexDirection: { xs: "column-reverse", md: "row" },
          p: {
            md: "64px",
            xs: "40px 24px",
          },
        }}>
        <Box
          sx={{
            width: {
              md: "60%",
              xs: "100%",
              display: "flex",
              justifyContent: "center",
            },
          }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: { md: "flex-start", xs: "center" },
              maxWidth: "400px",
              color: darkMode ? "#FFFFFF" : "#333333",
            }}>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "700",
                lineHeight: "36px",
                letterSpacing: "-0.25px",
              }}>
              Revolutionize Your Cold Calling Campaigns!
            </Typography>
            <Typography
              sx={{
                mt: { md: "20px", xs: "16px" },
                fontSize: { md: "18px", xs: "18px" },
                fontWeight: "400",
                lineHeight: { md: "28px", xs: "24px" },
                letterSpacing: "-0.25px",
                mb: {
                  md: "64px",
                  xs: "32px",
                },
              }}>
              Unlock the Power of AI-Driven Sales with Sales Hero. Experience
              seamless campaign management, real-time analytics, and unmatched
              performance.
            </Typography>
            <Button
              onClick={() => navigate("/contact-us")}
              sx={{
                textTransform: "none",
                height: "48px",
                width: {
                  xs: "100%",
                  md: "129px",
                },
                alignSelf: "left",
                backgroundColor: darkMode ? "#0F65C7" : "#0F65C7",
                color: darkMode ? "#FFFFFF" : "#FFFFFF",
                mt: "16px", // Adjust margin top for dark mode if needed
              }}>
              Get started
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            display: {
              md: "flex",
              xs: "none",
            },
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}>
          <img
            style={{ width: "400px" }}
            src={require("../../asets/images/robot.png")}
            alt="Robot"
          />
        </Box>
      </Box>
    </Box>
  );
}

export default ColdCalling;
