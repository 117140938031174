import React from "react";
import { Box, Typography, Divider } from "@mui/material";
// import { LinkedIn, Facebook, GitHub, Twitter } from "@mui/icons-material";
// import { useNavigate } from "react-router-dom"; // Import useNavigate

const Footer = () => {
    // const navigate = useNavigate();
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                mt: 8,
                mb: 4,
            }}
        >
            <Divider
                sx={{ width: { md: "85%", sm: "90%", xs: "95%" }, mb: 2 }}
            />
            <Box
                sx={{
                    width: { md: "85%", sm: "90%", xs: "95%" },
                    display: "flex",
                    flexDirection: { sm: "row", xs: "column" },
                    justifyContent: "space-between",
                    alignItems: "center",
                    textAlign: "center",
                }}
            >
                <Typography
                    sx={{
                        color: "rgba(102, 112, 133, 1)",
                        mb: { xs: 2, sm: 0 },
                    }}
                >
                    © 2024 First Wave Ai. All rights reserved.
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        mb: { xs: 2, sm: 0 },
                    }}
                >
                    {/* <IconButton
            onClick={() => navigate("https://twitter.com")}
            sx={{ color: "rgba(152, 162, 179, 1)" }}>
            <Twitter />
          </IconButton>
          <IconButton
            onClick={() => navigate("https://linkedin.com")}
            sx={{ color: "rgba(152, 162, 179, 1)" }}>
            <LinkedIn />
          </IconButton>
          <IconButton
            onClick={() => navigate("https://facebook.com")}
            sx={{ color: "rgba(152, 162, 179, 1)" }}>
            <Facebook />
          </IconButton>
          <IconButton
            onClick={() => navigate("https://github.com")}
            sx={{ color: "rgba(152, 162, 179, 1)" }}>
            <GitHub />
          </IconButton> */}
                </Box>
            </Box>
        </Box>
    );
};

export default Footer;
