import React from "react";
import {
	//Container,
	Typography,
	Grid,
	//Card,
	//CardContent,
	Link,
	//IconButton,
	Box,
	Button,
} from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import DescriptionIcon from "@mui/icons-material/Description";
import CreditCardIcon from "@mui/icons-material/CreditCard";
//import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
// import ChatIcon from '@mui/icons-material/Chat';

const faqs = [
	{
		icon: <FavoriteBorderIcon />,
		question: "Is there a free trial available?",
		answer:
			"Yes, you can try us for free for 30 days. Our friendly team will work with you to get you up and running as soon as possible.",
	},
	{
		icon: <SwapHorizIcon />,
		question: "Can I change my plan later?",
		answer:
			"Of course. Our pricing scales with your company. Chat to our friendly team to find a solution that works for you.",
	},
	{
		icon: <DoDisturbAltIcon />,
		question: "What is your cancellation policy?",
		answer:
			"We understand that things change. You can cancel your plan at any time and we’ll refund you the difference already paid.",
	},
	{
		icon: <DescriptionIcon />,
		question: "Can other info be added to an invoice?",
		answer:
			"At the moment, the only way to add additional information to invoices is to add the information to the workspace's name.",
	},
	{
		icon: <CreditCardIcon />,
		question: "How does billing work?",
		answer:
			"Plans are per workspace, not per account. You can upgrade one workspace, and still have any number of free workspaces.",
	},
	{
		icon: <MailOutlinedIcon />,
		question: "How do I change my account email?",
		answer:
			"You can change the email address associated with your account by going to untitled.com/account from a laptop or desktop.",
	},
];

const FAQSection = () => {
	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				mt: {
					md: "96px",
					xs: "64px",
				},
			}}
		>
			<Box
				sx={{
					width: { md: "85%", xs: "90%", sm: "90%" },
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						mb: { md: "64px", xs: "48px" },
						maxWidth: "700px",
					}}
				>
					<Typography
						variant="h4"
						sx={{
							//styleName: Display md/Semibold;
							// font-family: Inter;
							fontSize: "36px",
							fontWeight: "600",
							lineHeight: "44px",
							letterSpacing: "-0.02em",
							textAlign: "left",
						}}
						gutterBottom
					>
						FAQs
					</Typography>

					<Typography
						variant="body1"
						sx={{
							mt: "20px",
							//styleName: Text md/Regular;
							// font-family: Inter;
							fontSize: "20px",
							fontWeight: "400",
							lineH: "30px",
							textAlign: "left",
							color: "#475467",
						}}
						gutterBottom
					>
						Everything you need to know about the product and billing. Can’t
						find the answer you’re looking for? Please{" "}
						<Link
							href="#"
							sx={{
								color: "#475467",
								borderBottom: "1px solid #475467",
								textDecoration: "none",
							}}
							// underline='hover'
						>
							chat to our friendly team
						</Link>
						.
					</Typography>
				</Box>
				<Grid container spacing={3}>
					{faqs.map((faq, index) => (
						<Grid item xs={12} sm={6} md={4} key={index}>
							{/* <Card>
              <CardContent> */}
							<Button
								sx={{
									width: "48px",
									height: "48px",
									color: "#344054",
									border: "solid 1px #EAECF0",
									borderRadius: "8px",
								}}
							>
								{faq.icon}
							</Button>

							<Typography
								variant="h6"
								sx={{
									//styleName: Text xl/Semibold;
									// font-family: Inter;
									fontSize: "20px",
									fontWeight: "600",
									lineHeight: "30px",
									textAlign: "left",
									mb: "8px",

									mt: "20px",
									color: "#101828",
								}}
								component="div"
							>
								{faq.question}
							</Typography>
							<Typography
								variant="body2"
								sx={{
									//styleName: Text md/Regular;
									// font-family: Inter;
									fontSize: "16px",
									fontWeight: "400",
									lineH: "24px",
									textAlign: "left",
								}}
								color="#475467"
							>
								{faq.answer}
							</Typography>
							{/* </CardContent>
            </Card> */}
						</Grid>
					))}
				</Grid>
			</Box>
		</Box>
	);
};

export default FAQSection;
