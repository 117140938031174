import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import { ThemeContext } from "../../context/ThemeContext";

export const IndustryNicheHeader = ({ article }) => {
	const { darkMode } = useContext(ThemeContext);

	return (
		<Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", backgroundColor: darkMode ? "#121212" : "#E3EFFD", paddingTop: { md: "190px", xs: "100px" }, paddingBottom: { md: "110px", xs: "64px" } }} >
			<Box sx={{ display: "flex", flexDirection: "column", textAlign: { xs: "left", md: "center" }, width: { md: "54%", lg: "54%", xl: "54%", sm: "90%", xs: "95%" } }} >
				<Typography sx={{ fontSize: "40px", fontWeight: "800", lineHeight: "62px", color: darkMode ? "#ffffff" : "#000000" }} >
					{ article.header }
				</Typography>

				<Typography sx={{ fontSize: "20px", fontWeight: "400", lineHeight: "30px", color: darkMode ? "#B0B0B0" : "#666666", mt: "16px", letterSpacing: "-0.25px"}}>
					{ article.header_description }
				</Typography>
			</Box>
		</Box>
	);
}
