import React from "react";
import {
	Container,
	//Grid,
	Typography,
	Box,
	//Paper,
	//SvgIcon,
} from "@mui/material";
// import {
// 	AccessTime,
// 	Build,
// 	BarChart,
// 	Translate,
// 	Language,
// 	SupervisorAccount,
// } from "@mui/icons-material";

// const features = [
//   {
//     title: "Comprehensive AI-Driven Solutions",
//     description:
//       "Access a full suite of AI-powered tools that enhance both sales outreach and customer service interactions, while managing customer engagement efficiently.",
//     icon: Build,
//   },
//   {
//     title: "24/7 Availability and Reliability",
//     description:
//       "Provide round-the-clock support with AI assistants that handle customer calls any time, any day. Ensure continuous and reliable customer service without delays.",
//     icon: AccessTime,
//   },
//   {
//     title: "Control exactly how the AI responds to customers",
//     description:
//       "AI interactions tailored to specific needs ensure 100% relevant responses. Whether it's customized sales scripts, updated customer service FAQs, or automated voice-based workflows.",
//     icon: SupervisorAccount,
//   },
//   {
//     title: "Watch your performance in real time, and track data analytics",
//     description:
//       "Seamless integration with CRM systems and detailed analytics on performance metrics enable data-driven decisions and optimized sales strategies.",
//     icon: BarChart,
//   },
//   {
//     title: "Infinitely scalable AI",
//     description:
//       "Support the scalability of sales campaigns and customer service operations, allowing businesses to handle large volumes of interactions efficiently while reducing operational costs.",
//     icon: Language,
//   },
//   {
//     title: "Multi-Language Support",
//     description:
//       "Leverage advanced AI technology to provide natural and effective interactions with potential customers and consumers, improving engagement and satisfaction.",
//     icon: Translate,
//   },
// ];

export const Features = () => {
	return (
		<Box sx={{ py: 6 }}>
			<Container>
				<Box sx={{ textAlign: "center", mb: 6 }}>
					<Typography variant="h6" sx={{ color: "#0F65C7" }}>
						Our Products
					</Typography>
					<Typography
						variant="h4"
						sx={{ fontWeight: 700, mb: 1, color: "#0D58AE" }}
					>
						Sales Hero & Customer Sidekick
					</Typography>
					<Typography variant="body2" color="#5F6368">
						AI Solutions for Optimizing Sales and Enhancing Customer Support
					</Typography>
				</Box>
				{/* <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <Paper elevation={0} sx={{ p: 4, borderRadius: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                    color: "#0D58AE",
                  }}>
                  <SvgIcon
                    component={feature.icon}
                    sx={{ fontSize: 32, mr: 2 }}
                  />
                  <Typography variant="h6">{feature.title}</Typography>
                </Box>
                <Typography variant="body2" color="#5F6368">
                  {feature.description}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid> */}
			</Container>
		</Box>
	);
};
