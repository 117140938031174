import React, { useContext, useEffect } from "react";
import {
	Box,
	Container,
	Grid,
	Typography,
	Card,
	CardContent,
	Button,
	//Avatar,
} from "@mui/material";
import HeroImage from "../../asets/images/global.png";
import DashboardIcon from "../../asets/images/messageIcon.png"; // Update with actual service icon paths
import CampaignIcon from "../../asets/images/messageIcon.png";
import LanguageIcon from "../../asets/images/messageIcon.png";
import DataIntegrationIcon from "../../asets/images/messageIcon.png";
import AutomatedCallingIcon from "../../asets/images/messageIcon.png";
import LeadDataIcon from "../../asets/images/messageIcon.png";
import CRMIntegrationIcon from "../../asets/images/messageIcon.png";
import MetricsAnalyticsIcon from "../../asets/images/messageIcon.png";
import RealTimeMonitoringIcon from "../../asets/images/messageIcon.png";
import ScalabilityIcon from "../../asets/images/messageIcon.png";
import CustomizableScriptsIcon from "../../asets/images/messageIcon.png";
import UserPermissionsIcon from "../../asets/images/messageIcon.png";
import CustomerSupportIcon from "../../asets/images/messageIcon.png";
import ComplianceIcon from "../../asets/images/messageIcon.png";
//import Footer from "../../Components/Footer";
import FirstWaveAi from "../../Components/FirstWaveAi";
import Notified from "../../Components/Notified";
import ColdCalling from "../../Components/ColdCalliing";
import Question from "../../Components/Question";
//import SuccessStory from "../../Components/SuccessStory";
//import PriceCard from "../../Components/PriceCard/idex";
//import PriceTable from "../../Components/PriceTable";
import SaleHeroHeader from "../../Components/SaleHeroHeader";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../context/ThemeContext";

export const Product = () => {
	const { darkMode } = useContext(ThemeContext);
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const services = [
		{
			title: "Dashboard Access",
			description:
				"Access to real-time information for better decision-making and tracking.",
			icon: DashboardIcon,
		},
		{
			title: "Campaign Targeting",
			description:
				"Identify and engage specific target groups with customized campaigns.",
			icon: CampaignIcon,
		},
		{
			title: "Language Customization",
			description:
				"Communicate with clients in their preferred language for better engagement.",
			icon: LanguageIcon,
		},
		{
			title: "Data Integration",
			description:
				"Seamlessly integrate with your existing tools and systems for enhanced productivity.",
			icon: DataIntegrationIcon,
		},
		{
			title: "Automated Calling",
			description:
				"Let AI handle the tedious task of cold calling, freeing up time for more strategic work.",
			icon: AutomatedCallingIcon,
		},
		{
			title: "Lead Data Collection",
			description:
				"Gather and analyze leads' data for more effective marketing and sales strategies.",
			icon: LeadDataIcon,
		},
		{
			title: "CRM Integration",
			description:
				"Integrate with your existing CRM systems to streamline operations and improve customer management.",
			icon: CRMIntegrationIcon,
		},
		{
			title: "Metrics and Analytics",
			description:
				"Track key metrics and gain insights to optimize your sales and marketing efforts.",
			icon: MetricsAnalyticsIcon,
		},
		{
			title: "Real-Time Monitoring",
			description:
				"Monitor performance in real-time to make timely adjustments and improve results.",
			icon: RealTimeMonitoringIcon,
		},
		{
			title: "Scalability",
			description:
				"Easily scale your operations to meet the growing demands of your business.",
			icon: ScalabilityIcon,
		},
		{
			title: "Customizable Scripts",
			description:
				"Create and utilize custom scripts for more effective communication with clients.",
			icon: CustomizableScriptsIcon,
		},
		{
			title: "User Permissions",
			description:
				"Manage user access and permissions to ensure data security and integrity.",
			icon: UserPermissionsIcon,
		},
		{
			title: "Customer Support",
			description:
				"Get prompt assistance to resolve any issues and ensure smooth operations.",
			icon: CustomerSupportIcon,
		},
		{
			title: "Compliance",
			description:
				"Ensure all operations are compliant with relevant regulations and standards.",
			icon: ComplianceIcon,
		},
	];

	const CallToActionCard = ({ onNavigate }) => (
		<Card
			sx={{
				height: "100%",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				backgroundColor: "#1976d2",
				color: "#fff",
			}}
		>
			<CardContent sx={{ textAlign: "center" }}>
				<Typography variant="h5" component="h2" gutterBottom>
					Get Started Now
				</Typography>
				<Typography variant="body1" paragraph>
					Take your business to greater heights! Have questions or queries?
					Contact Us!
				</Typography>
				<Button
					onClick={onNavigate}
					variant="contained"
					sx={{ mt: 2, backgroundColor: "#fff", color: "#1976d2" }}
				>
					Get Started Today!
				</Button>
			</CardContent>
		</Card>
	);

	return (
		<>
			<SaleHeroHeader />

			<Container className="container">
				{/* Hero Section */}
				<Box className="unlock-section">
					<Grid container spacing={4} alignItems="center">
						<Grid item xs={12} md={6}>
							<Typography
								component="p"
								sx={{
									display: "inline",
									fontWeight: "400",
									fontSize: 36,
									lineHeight: { md: "44px", xs: "38px" },
									color: darkMode ? "#B0B0B0" : "#101828",
								}}
							>
								Let your business Rise with sales Hero
							</Typography>
							<Typography variant="body1" paragraph>
								It’s a sales tool on call, day & night, our custom algorithms
								use our AI-based tech to change how many industries work and
								compete in their space. The advanced tools help to utilize all
								of their time. Our AI tool interacts with targets, manages CRM,
								handles the heavy stuff and automates the task that doesn't need
								human touch. The easy-to-use AI tool for enhancing sales &
								marketing capabilities. It is the perfect AI sales tool. This
								technology gets you in touch with your clients and provides you
								with insightful data that will help you close more deals. The AI
								Engine increases conversions rates, shortens the sales cycle,
								targets repetitive services in a more timely manner.
							</Typography>
							<Button
								onClick={() => navigate("/contact-us")}
								variant="contained"
								sx={{ mt: 2 }}
							>
								Get Started
							</Button>
						</Grid>
						<Grid item xs={12} md={6}>
							<Box
								component="img"
								src={HeroImage}
								alt="Office Image"
								sx={{ maxWidth: "100%", height: "auto" }}
							/>
						</Grid>
					</Grid>
				</Box>

				{/* Services Section */}
				<Box className="services-section">
					<Typography variant="h5" component="h2" gutterBottom>
						Services that will help you Grow
					</Typography>
					<Typography variant="body1" paragraph>
						Powerful, self-service product and growth benefits to help you
						convert, engage, and retain more users. Trusted by over 4,000
						startups.
					</Typography>
					<Grid container spacing={4}>
						{services.map((service, index) => (
							<Grid item xs={12} sm={6} md={4} key={index}>
								<Card
									className="service-card"
									sx={{
										height: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "space-between",
									}}
								>
									<CardContent>
										<Box
											component="img"
											src={service.icon}
											alt={`Service ${index + 1}`}
											sx={{ width: 50, height: 50, mb: 2 }}
										/>
										<Typography variant="h6" component="h3" gutterBottom>
											{service.title}
										</Typography>
										<Typography variant="body2">
											{service.description}
										</Typography>
									</CardContent>
								</Card>
							</Grid>
						))}
						<Grid item xs={12} sm={6} md={4}>
							<CallToActionCard onNavigate={() => navigate("/contact-us")} />
						</Grid>
					</Grid>
				</Box>

				{/* Success Stories Section */}
				{/* <SuccessStory /> */}
				{/* <PriceTable /> */}

				{/* Purchase Section */}
				<Box className="purchase-section">
					<Typography variant="h5" component="h2" gutterBottom>
						How do I purchase Sales Hero?
					</Typography>
					<Typography variant="body1" paragraph>
						Sales Hero operates on a pay-per-use model, allowing you to get full
						support and pay for each campaign you run by simply creating a
						campaign in the dashboard and charging for the actions you take.
					</Typography>
					<Button onClick={() => navigate("/contact-us")} variant="contained">
						Get Started
					</Button>
				</Box>
			</Container>
			<Question />
			<ColdCalling />
			<Notified />
			<FirstWaveAi />
		</>
	);
};
