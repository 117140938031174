import React, { useEffect } from "react";
import {
	Alert,
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Container,
	Grid,
	Link,
	TextField,
	Typography,
	//FormControlLabel,
	//Checkbox,
} from "@mui/material";
import { Google } from "@mui/icons-material";
import Notified from "../../Components/Notified";
import FirstWaveAi from "../../Components/FirstWaveAi";
import { useNavigate } from "react-router-dom";
import { checkValue } from "../../helpers/validation";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, signin } from "../../services/firebase";
import { ThemeContext } from "../../context/ThemeContext";
import { createUserBusiness, getUserBusiness } from "../../services/business";

export const Signin = () => {
	const navigation = useNavigate();
	const { darkMode } = React.useContext(ThemeContext);
	const [showAlert, setShowAlert] = React.useState(false);
	const [alertText, setAlertText] = React.useState("");
	const [isLoading, setIsLoading] = React.useState(false);
	const [dataValues, setDataValues] = React.useState({
		email: { value: "", error: false },
		password: { value: "", error: false },
	});
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const emptyValues =
		Object.keys(dataValues).filter((key) => dataValues[key].value.length === 0)
			.length === 0;

	async function handleSignIn(e) {
		e.preventDefault();

		try {
			if (!emptyValues) {
				setAlertText("There are some form values empty");
				setShowAlert(true);
				return;
			}

			setIsLoading(true);
			const userCreds = await signin(
				dataValues.email.value,
				dataValues.password.value
			);

			if (userCreds?.code && userCreds.code.includes("invalid-credential")) {
				setAlertText("Invalid credentials used");
				setShowAlert(true);
				setIsLoading(false);
				return;
			}

			window.location.href = `${process.env.REACT_APP_DASHBOARD_URL}${userCreds}`;

			setIsLoading(false);
		} catch (error) {
			console.error("Error signing in with google", error);
			setIsLoading(false);
		}
	}

	async function googleSignIn() {
		const provider = new GoogleAuthProvider();

		try {
			setIsLoading(true);
			const userCreds = await signInWithPopup(auth, provider);
			const hasUserBusiness = await getUserBusiness(
				userCreds.user.reloadUserInfo.localId
			);

			if (!hasUserBusiness) {
				setAlertText("User not registered");
				setShowAlert(true);
				setIsLoading(false);
				return;
			}
			setIsLoading(false);
			window.location.href = `${process.env.REACT_APP_DASHBOARD_URL}${userCreds.user.reloadUserInfo.localId}`;
		} catch (error) {
			console.error("Error signing in with google", error);
			setIsLoading(false);
		}
	}

	return (
		<>
			<Container component="main" maxWidth="xs">
				<Box
					sx={{
						marginTop: 14,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					{showAlert && (
						<Alert severity="warning" onClose={() => setShowAlert(false)}>
							{alertText}
						</Alert>
					)}
					<Backdrop
						sx={{
							color: darkMode ? "#303030" : "#f5f5f5",
							zIndex: (theme) => theme.zIndex.drawer + 1,
						}}
						open={isLoading}
					>
						<CircularProgress size={60} />
					</Backdrop>
					<Typography
						component="h1"
						variant="h5"
						sx={{ fontWeight: "bold", textAlign: "center", mt: 8 }}
					>
						Log in to your account
					</Typography>
					<Typography
						component="h2"
						variant="body2"
						sx={{ mt: 1, textAlign: "center" }}
					>
						Welcome back! Please enter your details.
					</Typography>
					<Box
						component="form"
						noValidate
						sx={{ mt: 3 }}
						onSubmit={handleSignIn}
					>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="email"
							label="Email"
							name="email"
							autoComplete="email"
							autoFocus
							error={dataValues.email.error}
							value={dataValues.email.value}
							onChange={(e) =>
								setDataValues({ ...dataValues, email: checkValue(e, "email") })
							}
						/>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							name="password"
							label="Password"
							type="password"
							id="password"
							autoComplete="current-password"
							error={dataValues.password.error}
							value={dataValues.password.value}
							onChange={(e) =>
								setDataValues({
									...dataValues,
									password: checkValue(e, "password"),
								})
							}
						/>
						{/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember for 30 days"
              sx={{ alignSelf: "flex-start", mt: 1 }}
            /> */}
						{/* <Link
              href="#"
              variant="body2"
              sx={{ alignSelf: "flex-end", mt: -3 }}>
              Forgot password
            </Link> */}
						<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 2, backgroundColor: "#1976d2", color: "#fff" }}
						>
							Sign in
						</Button>
						<Button
							fullWidth
							variant="outlined"
							startIcon={<Google />}
							sx={{ mt: 1, mb: 2, backgroundColor: "#fff", color: "#757575" }}
							onClick={googleSignIn}
						>
							Sign in with Google
						</Button>
						<Grid container justifyContent="center">
							<Grid item>
								<Link
									sx={{ cursor: "pointer" }}
									onClick={() => navigation("/signup")}
									variant="body2"
								>
									{"Don't have an account? Sign up"}
								</Link>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Container>
			<Notified />
			<FirstWaveAi />
		</>
	);
};

export default Signin;
