import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

function StillHaveQuestion() {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          width: {
            md: "87%",
            xs: "95%",
            sm: "90%",
            lg: "87%",
          },
          marginTop: "40px",
          padding: "20px",
          backgroundColor: "#F9FAFB",
          borderRadius: "8px",
          p: "40px 0px",
        }}>
        <img
          style={{ maxWidth: "150px" }}
          //   width='100%'
          src={require("../../asets/images/groupAvatar.png")}
        />
        <Typography
          variant="h6"
          align="center"
          sx={{
            mt: { md: "32px", xs: "24px" },
            color: "rgba(16, 24, 40, 1)",
            //styleName: Text xl/Semibold;

            fontSize: "20px",
            fontWeight: "600",
            lineHeight: "30px",
            // textAlign: center;
          }}>
          Still have questions?
        </Typography>
        <Typography
          variant="body2"
          align="center"
          sx={{
            color: "rgba(71, 84, 103, 1)",
            //styleName: Text xl/Semibold;

            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "26px",
            mt: "8px",
            // textAlign: center;
          }}>
          Can’t find the answer you’re looking for? Please chat to our friendly
          team.
        </Typography>
        <Button
          onClick={() => navigate("/contact-us")}
          variant="contained"
          sx={{
            mt: { xs: "24px", md: "32px" },
            textTransform: "none",
            width: "134px",
          }}
          color="primary">
          Get in touch
        </Button>
      </Box>
    </Box>
  );
}

export default StillHaveQuestion;
