import { Box } from "@mui/material";
import React from "react";
import HowPurchase from "../../Components/HowParchase";
import ConsistentlyAuthority from "../../Components/ConsistentlyAuthority";

function SalesSidekick() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}>
      <HowPurchase />
      <ConsistentlyAuthority />
    </Box>
  );
}

export default SalesSidekick;
