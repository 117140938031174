import React, { useState } from "react";
import {
	Typography,
	Grid,
	Box,
	Link,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const FaqsHome = () => {
	const [expanded, setExpanded] = useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				mt: { md: "96px", xs: "64px" },
				overflow: "hidden",
				px: { xs: "16px", md: "0" },
			}}
		>
			<Box
				sx={{
					width: { md: "85%", xs: "100%" },
				}}
			>
				<Grid container spacing={3}>
					<Grid item xs={12} md={6}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								mb: { md: "64px", xs: "48px" },
							}}
						>
							<Typography
								sx={{
									fontFamily: "Inter",
									fontSize: "16px",
									fontWeight: "600",
									lineHeight: "24px",
									color: "#0F65C7",
									mb: "12px",
								}}
							>
								Support
							</Typography>
							<Typography
								variant="h4"
								sx={{
									fontFamily: "Inter",
									fontSize: { xs: "28px", md: "36px" },
									fontWeight: "600",
									lineHeight: { xs: "34px", md: "44px" },
									letterSpacing: "-0.02em",
									textAlign: "left",
								}}
								gutterBottom
							>
								FAQs
							</Typography>
							<Typography
								variant="body1"
								sx={{
									mt: "20px",
									fontFamily: "Inter",
									fontSize: "20px",
									fontWeight: "400",
									lineHeight: "30px",
									textAlign: "left",
									color: "#475467",
								}}
								gutterBottom
							>
								Frequently Asked Questions for First Wave AI. Can’t find the
								answer you’re looking for? Reach out to sales@firstwaveai.com
								<Link
									href="#"
									sx={{
										color: "#475467",
										borderBottom: "1px solid #475467",
										textDecoration: "none",
									}}
								>
									chat to our friendly team
								</Link>
								.
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} md={6}>
						<Box
							sx={{
								overflowY: "hidden",
							}}
						>
							{[
								{
									question: "Is there a free trial available?",
									answer:
										"Yes, you can try us for free for 2 weeks. We’re confident our tools give outsize value for your business.",
								},
								{
									question: "Can I change my plan later?",
									answer:
										"Of course! Our pricing grows with your company. Pick a plan that works for you now, with the confidence you can scale up or down as needed.",
								},
								{
									question: "What is your cancellation policy?",
									answer:
										"Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
								},
								{
									question: "Can other info be added to an invoice?",
									answer:
										"We’re working on enhancing the business details section. If you need to change information for now, please reach out to support@firstwaveai.com.",
								},
								{
									question: "How does billing work?",
									answer:
										"Plans are per phone line, not per account. We’re working on adding enhanced features for many phone lines and many organizations for a single account to make management easier.",
								},
								{
									question: "How do I change my account email?",
									answer:
										"You can change the email address associated with your account by emailing support@firstwaveai.com",
								},
							].map((item, index) => (
								<Accordion
									key={index}
									expanded={expanded === `panel${index}`}
									onChange={handleChange(`panel${index}`)}
									sx={{
										marginBottom: "10px",
										boxShadow: "none",
									}}
								>
									<AccordionSummary
										expandIcon={
											expanded === `panel${index}` ? (
												<RemoveIcon />
											) : (
												<AddIcon />
											)
										}
										aria-controls={`panel${index}bh-content`}
										id={`panel${index}bh-header`}
									>
										<Typography
											sx={{
												fontFamily: "Inter",
												fontSize: "18px",
												fontWeight: "550",
												lineHeight: "28px",
												color: "#101828",
											}}
										>
											{item.question}
										</Typography>
									</AccordionSummary>
									<AccordionDetails
										sx={{
											fontFamily: "Inter",
											fontSize: "16px",
											fontWeight: "300",
											lineHeight: "24px",
											color: "rgba(71, 84, 103, 1)",
										}}
									>
										<Typography>{item.answer}</Typography>
									</AccordionDetails>
								</Accordion>
							))}
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default FaqsHome;
