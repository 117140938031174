import React, { useContext } from "react";
import { Container, Grid, Typography, Button, Box } from "@mui/material";
import { ThemeContext } from "../../context/ThemeContext";
import { useNavigate } from "react-router-dom";

const PotentialSales = () => {
	const { darkMode } = useContext(ThemeContext);
	const navigate = useNavigate();

	const styles = {
		container: {
			padding: { md: "32px", xs: "16px" },
		},
		buttonContainer: {
			marginTop: "24px",
		},
		button: {
			marginRight: "16px",
			textTransform: "none",
			color: darkMode ? "#fff" : "#344054",
			width: "134px",
			height: "48px",
			border: darkMode ? "1px solid #424242" : "1px solid #D0D5DD",
			backgroundColor: darkMode ? "#424242" : "#FFFFFF",
		},
	};

	return (
		<Container sx={styles.container}>
			<Grid container spacing={4} alignItems="center">
				<Grid item xs={12} lg={6}>
					<Typography
						variant="h6"
						sx={{
							fontSize: "16px",
							fontWeight: "600",
							lineHeight: "24px",
							color: "#0F65C7",
							textAlign: { xs: "left", md: "left" },
							mb: "12px",
						}}
					>
						Unlock Your Sales Potential with Sales Hero
					</Typography>
					<Typography
						component="p"
						sx={{
							display: "inline",
							fontWeight: "400",
							fontSize: 36,
							lineHeight: { md: "44px", xs: "38px" },
							color: darkMode ? "#B0B0B0" : "#101828",
							mb: "12px",
						}}
					>
						Let your business Rise with Sales Hero
					</Typography>
					<Typography
						variant="body1"
						sx={{
							fontSize: { md: "18px", xs: "16px" },
							fontWeight: "400",
							lineHeight: { md: "28px", xs: "24px" },
							color: darkMode ? "#bdbdbd" : "#475467",
							textAlign: "left",
						}}
					>
						Sales Hero is a phone-based AI for cold calling. Your cold call
						campaign starts in the dashboard where you set all your campaign
						parameters. There, select the total campaign dollar spend, adjust
						the language, and set your A/B tests for an unlimited amount of
						sales scripts. From the dashboard you’ll upload target phone numbers
						for warm leads, names, descriptors, to the sales bot, or you can
						connect to a database like Zoom Info and pull the data into the
						campaign. When you initiate the campaign, our system calls all the
						listed people using the sales scripts you provide! You can also set
						your call to action, and the AI will collect prospective candidates’
						data and feed it into your CRM. Lastly, you’ll have a live feed on
						your metrics and conversion rates.
					</Typography>

					<Box sx={styles.buttonContainer}>
						<Button
							onClick={() => navigate("/contact-us")}
							variant="contained"
							sx={styles.button}
						>
							Get in touch
						</Button>
					</Box>
				</Grid>
				<Grid
					item
					xs={12}
					lg={6}
					sx={{
						height: { md: "600px", xs: "250px" },
						maxHeight: "600px",
						minHeight: "240px",
						display: "flex",
						alignItems: "center",
					}}
				>
					<img
						style={{
							width: "100%",
							height: "100%",
							objectFit: "cover",
						}}
						src={require("../../asets/images/office.jpeg")}
						alt="Office"
					/>
				</Grid>
			</Grid>
		</Container>
	);
};

export default PotentialSales;
