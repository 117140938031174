import { Box, Typography, Button } from "@mui/material";
import React, { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";
//import AboutHeaderImage from "../../asets/images/AboutHeaderImage.png";

export default function PricingPageHeader({ activeButton, setActiveButton }) {
	const { darkMode } = useContext(ThemeContext);

	// Inline styles
	const baseStyle = {
		boxShadow: "none",
		textTransform: "none",
		padding: "6px 12px",
		borderRadius: "8px",
		fontSize: "16px",
		width: { md: "174px", xs: "153px" },
	};

	const salesHeroStyle = {
		...baseStyle,
		color:
			activeButton === "Sales Hero"
				? darkMode
					? "#000000"
					: "#ffffff"
				: "#3c4043",
		backgroundColor:
			activeButton === "Sales Hero"
				? darkMode
					? "#ffffff"
					: "#1a73e8"
				: darkMode
				? "#444"
				: "#ffffff",
		"&:hover": {
			backgroundColor:
				activeButton === "Sales Hero"
					? darkMode
						? "#ffffff"
						: "#1a73e8"
					: "inherit",
		},
	};

	const salesSidekickStyle = {
		...baseStyle,
		color:
			activeButton === "Sales Sidekick"
				? darkMode
					? "#000000"
					: "#ffffff"
				: "#3c4043",
		backgroundColor:
			activeButton === "Sales Sidekick"
				? darkMode
					? "#ffffff"
					: "#1a73e8"
				: darkMode
				? "#444"
				: "#ffffff",
		"&:hover": {
			backgroundColor:
				activeButton === "Sales Sidekick"
					? darkMode
						? "#ffffff"
						: "#1a73e8"
					: "inherit",
		},
	};

	const handleButtonClick = (button) => {
		setActiveButton(button);
	};

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				flexDirection: "column",
				backgroundColor: darkMode ? "#121212" : "#E3EFFD",
				paddingTop: { md: "190px", xs: "100px" },
				paddingBottom: { md: "110px", xs: "64px" },
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					textAlign: { xs: "left", md: "center" },
					width: { md: "54%", lg: "54%", xl: "54%", sm: "90%", xs: "95%" },
				}}
			>
				<Typography
					sx={{
						fontSize: "16px",
						fontWeight: "600",
						lineHeight: "24px",
						color: darkMode ? "#0F65C7" : "#0F65C7",
						textAlign: { xs: "left", md: "center" },
						mb: "12px",
					}}
				>
					Pricing
				</Typography>
				<Typography
					sx={{
						fontSize: "52px",
						fontWeight: "800",
						lineHeight: "62px",
						color: darkMode ? "#ffffff" : "#000000",
					}}
				>
					Plans that fit your scale
				</Typography>
				<Typography
					sx={{
						fontSize: "20px",
						fontWeight: "400",
						lineHeight: "30px",
						color: darkMode ? "#B0B0B0" : "#666666",
						mt: "16px",
						letterSpacing: "-0.25px",
					}}
				>
					Simple, transparent pricing that grows with you.
				</Typography>
			</Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					width: { md: "54%", lg: "54%", xl: "54%", sm: "90%", xs: "95%" },
				}}
			>
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					sx={{
						maxWidth: { md: "384px", xs: "343px" },
						mt: { md: "60px", xs: "16px" },
						borderRadius: "8px",
						display: "flex",
						justifyContent: "space-between",
						gap: "5px",
						p: "12px",
						backgroundColor: darkMode ? "#333" : "rgba(255, 255, 255, 1)",
					}}
				>
					<Button
						sx={salesSidekickStyle}
						onClick={() => handleButtonClick("Sales Sidekick")}
					>
						Customer Sidekick
					</Button>
					<Button
						sx={salesHeroStyle}
						onClick={() => handleButtonClick("Sales Hero")}
					>
						Sales Hero
					</Button>
				</Box>
			</Box>
		</Box>
	);
}
