import { Box, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";

function NiceToMeetYou() {
  const { darkMode } = useContext(ThemeContext);

  return (
    <Box
      sx={{
        display: "flex",
        mt: { md: "96px", xs: "64px" }, // Reduced top margin
        mb: { md: "96px", xs: "64px" }, // Added bottom margin
        justifyContent: "center",
        width: "100%",
      }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { md: "80%", sm: "90%", xs: "95%" }, // Adjusted for a slightly tighter layout
        }}>
        <Typography
          sx={{
            fontSize: "20px", // Adjusted for consistency
            fontWeight: "bold", // Ensured it's bold
            lineHeight: "30px", // Increased line height for better readability
            color: darkMode ? "#BB86FC" : "#0F65C7",
            // textAlign: "center", // Center text alignment
            mb: "24px", // Increased space before the next section
          }}>
          Nice to meet you
        </Typography>
        <Grid container spacing={4} sx={{ alignItems: "flex-start" }}>
          <Grid item xs={12} md={7}>
            <Typography
              sx={{
                fontSize: { md: "36px", xs: "28px" }, // Reduced font size for mobile
                fontWeight: "600",
                lineHeight: { md: "48px", xs: "36px" }, // Adjusted line height
                letterSpacing: "normal", // Adjusted letter spacing
                color: darkMode ? "#ffffff" : "#101828",
                mb: { xs: "32px", md: "32px" }, // Uniform margin bottom
              }}>
              Our mission at First Wave AI is to build the best AI phone call
              tools possible
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography
              sx={{
                fontSize: { md: "18px", xs: "16px" },
                fontWeight: "400",
                lineHeight: { md: "28px", xs: "24px" },
                letterSpacing: "normal",
                color: darkMode ? "#cccccc" : "#475467",
                textAlign: "justify", // Justify text for cleaner presentation
              }}>
              We empower businesses of all sizes by providing them with
              intelligent tools that drive efficiency, improve customer
              interactions, and boost sales performance. We are committed to
              continuous innovation, customer success, and ethical AI practices.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default NiceToMeetYou;
