import React, { useContext } from "react";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { ThemeContext } from "../../context/ThemeContext";

function Notified() {
  const { darkMode } = useContext(ThemeContext);

  return (
    <Box
      sx={{
        mt: {
          md: "96px",
          xs: "64px",
        },
        width: "100%",
        display: "flex",
        justifyContent: "center",
        pt: {
          xs: "40px",
          md: "48px",
        },
        pb: {
          xs: "40px",
          md: "48px",
        },
        backgroundColor: darkMode ? "#1F1B24" : "rgba(249, 250, 251, 1)",
      }}>
      <Box
        sx={{
          width: {
            md: "85%",
            sm: "90%",
            xs: "95%",
          },
          textAlign: "center", // Center text within this box
        }}>
        <Grid container rowSpacing={2} justifyContent="center">
          <Grid
            item
            xs={12}
            md={7}
            display="flex"
            flexDirection="column"
            alignItems="center">
            <Typography
              sx={{
                fontSize: { md: "30px", xs: "24px" },
                fontWeight: "600",
                lineHeight: { md: "38px", xs: "32px" },
                color: darkMode ? "#ffffff" : "#000000",
              }}>
              Get notified about the latest at First Wave AI
            </Typography>
            <Typography
              sx={{
                mt: {
                  md: "16px",
                  xs: "8px",
                },
                fontSize: { md: "20px", xs: "18px" },
                fontWeight: "400",
                lineHeight: { md: "30px", xs: "28px" },
                color: darkMode ? "#cccccc" : "#5F6368",
              }}>
              Stay up to date with news, announcements, and articles.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            sx={{ mt: { xs: "32px", md: "0px" } }}
            display="flex"
            justifyContent="center">
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  md: "row",
                  xs: "column",
                },
                alignItems: "center",
                width: "100%",
                justifyContent: "center",
              }}>
              <TextField
                variant="outlined"
                placeholder="Enter your email"
                InputProps={{
                  sx: {
                    height: "100%",
                    padding: "10px",
                    backgroundColor: darkMode ? "#333333" : "white",
                    color: darkMode ? "#ffffff" : "inherit",
                    border: darkMode
                      ? "1px solid #555555"
                      : "1px solid #D0D5DD",
                    borderRadius: "5px",
                  },
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                }}
                sx={{
                  width: "100%",
                  height: "48px",
                  marginBottom: { xs: "16px", md: "0px" },
                }}
              />
              <Button
                sx={{
                  mt: { xs: "16px", md: "0px" },
                  ml: { md: "16px", xs: "0px" },
                  textTransform: "none",
                  height: "48px",
                  width: {
                    xs: "100%",
                    md: "115px",
                  },
                  backgroundColor: darkMode ? "#0F65C7" : "#0F65C7",
                  color: darkMode ? "#FFFFFF" : "#FFFFFF",
                }}>
                Subscribe
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Notified;
