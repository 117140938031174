import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";

export default function SaleHeroHeader({ activeButton, setActiveButton }) {
  const { darkMode } = useContext(ThemeContext);

  const baseStyle = {
    boxShadow: "none",
    textTransform: "none",
    padding: "6px 12px",
    borderRadius: "8px",
    fontSize: "16px",
    width: { md: "174px", xs: "153px" },
  };

  const salesHeroStyle = {
    ...baseStyle,
    color: activeButton === "Sales Hero" ? "#3c4043" : "#ffffff",
    backgroundColor: activeButton === "Sales Hero" ? "#ffffff" : "#1a73e8",
  };

  const salesSidekickStyle = {
    ...baseStyle,
    color: activeButton === "Sales Sidekick" ? "#3c4043" : "#ffffff",
    backgroundColor: activeButton === "Sales Sidekick" ? "#ffffff" : "#1a73e8",
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: darkMode ? "#121212" : "#E3EFFD", // Adjust background color based on dark mode
        paddingTop: { md: "190px", xs: "100px" },
        paddingBottom: { md: "110px", xs: "64px" },
      }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: { xs: "left", md: "center" },
          width: { md: "54%", lg: "54%", xl: "54%", sm: "90%", xs: "95%" },
          color: darkMode ? "#ffffff" : "#3c4043", // Adjust text color based on dark mode
        }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "24px",
            color: darkMode ? "#0F65C7" : "#0F65C7", // Adjust accent color based on dark mode
            textAlign: { xs: "left", md: "center" },
            mb: "12px",
          }}>
          Products
        </Typography>
        <Typography
          sx={{
            fontSize: "52px",
            fontWeight: "800",
            lineHeight: "62px",
          }}>
          Sales Hero
        </Typography>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "30px",
            color: darkMode ? "#cccccc" : "#666666", // Adjust body text color based on dark mode
            mt: "16px",
            letterSpacing: "-0.25px",
          }}>
          Sales Hero is your ultimate AI-powered sales assistant, designed to
          optimize your sales process and increase conversions.
        </Typography>
      </Box>
    </Box>
  );
}
