import React, { useEffect } from "react";
import EnableDevelopers from "../../Components/EnableDevelopers";
import { Box } from "@mui/material";
import NiceToMeetYou from "../../Components/NiceToMeetYou";
//import Investment from "../../Components/Investment";
import GettingStarted from "../../Components/GettingStarted/idex";
//import SuccessStory from "../../Components/SuccessStory";
//import FastGrowing from "../../Components/FastGrowing";
import ColdCalling from "../../Components/ColdCalliing";
import Notified from "../../Components/Notified";
import FirstWaveAi from "../../Components/FirstWaveAi";

function AboutUs() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const headerData = {
		pageName: "About us",
		title: "Calls should be pleasant, simple, and easy",
		description:
			"We’re customers just like everyone else. That’s why we’re building a better future starting with AI phone calls.",
	};
	return (
		<>
			<EnableDevelopers headerData={headerData} />
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					ml: { sm: "10%", xs: "5%" },
					width: { sm: "80%", xs: "90%" },
				}}
			>
				<NiceToMeetYou />
				{
					//<Investment />
				}
				<GettingStarted />
				{
					//<SuccessStory />
				}
				{
					//<FastGrowing />
				}
				<ColdCalling />
				<Notified />
				<FirstWaveAi />
			</Box>
		</>
	);
}

export default AboutUs;
