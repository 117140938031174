import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import "../../asets/fonts/fonts.css";
import { ThemeContext } from "../../context/ThemeContext";

const SalesWithCheckIcon = ({ TextContent }) => {
  const { darkMode } = useContext(ThemeContext);

  return (
    <Box
      sx={{
        padding: { xs: "16px", md: "24px" },
        marginLeft: "15px",
        backgroundColor: darkMode ? "#0C111D" : "#ffffff",
        color: darkMode ? "#ffffff" : "#000000",
        borderRadius: "8px",
        boxShadow: darkMode
          ? "0px 0px 10px rgba(0, 0, 0, 0.5)"
          : "0px 0px 10px rgba(0, 0, 0, 0.1)",
        maxWidth: "100%", // Ensure it doesn't overflow its parent
      }}>
      <Typography
        variant="h4"
        sx={{
          fontFamily: "Inter",
          fontSize: { xs: "24px", md: "36px" },
          fontWeight: 600,
          lineHeight: { xs: "32px", md: "44px" },
          letterSpacing: "-0.02em",
          textAlign: "left",
          color: darkMode ? "#1976d2" : "#0F65C7",
          marginBottom: "16px",
        }}>
        {TextContent.title}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontFamily: "Inter",
          fontSize: { xs: "16px", md: "18px" },
          lineHeight: { xs: "24px", md: "28px" },
          color: darkMode ? "#cccccc" : "#000000",
          marginBottom: "24px",
        }}>
        {TextContent.description}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        {TextContent.elements.map((element, index) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
            key={index}>
            <CheckCircleOutlineIcon
              sx={{ color: darkMode ? "#1976d2" : "#1976d2" }}
            />
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: { xs: "16px", md: "18px" },
                fontWeight: 400,
                lineHeight: { xs: "24px", md: "28px" },
                color: darkMode ? "#cccccc" : "#475467",
              }}>
              {element}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default SalesWithCheckIcon;
