import { Box, Typography, Fade } from "@mui/material";
import React, { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";

export default function EnableDevelopers({ headerData }) {
  const { darkMode } = useContext(ThemeContext);

  return (
    <Fade in={true} timeout={1000}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: darkMode ? "#1F1B24" : "#E3EFFD",
          paddingTop: { md: "190px", xs: "100px" },
          paddingBottom: { md: "110px", xs: "64px" },
          width: "100%",
        }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: { xs: "left", md: "center" },
            width: { md: "54%", lg: "54%", xl: "54%", sm: "90%", xs: "95%" },
          }}>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              lineHeight: "24px",
              color: darkMode ? "#BB86FC" : "#0F65C7",
              textAlign: { xs: "left", md: "center" },
              mb: "12px",
            }}>
            {headerData.pageName}
          </Typography>
          <Typography
            sx={{
              fontSize: "52px",
              fontWeight: "800",
              lineHeight: "62px",
              color: darkMode ? "#ffffff" : "inherit",
            }}>
            {headerData.title}
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "30px",
              color: darkMode ? "#cccccc" : "#666666",
              mt: "16px",
              letterSpacing: "-0.25px",
            }}>
            {headerData.description}
          </Typography>
        </Box>
      </Box>
    </Fade>
  );
}
