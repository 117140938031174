import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";

function ServicesHelp() {
	const { darkMode } = useContext(ThemeContext);

	return (
		<Box
			sx={{
				display: "flex",
				mb: {
					md: "96px",
					xs: "64px",
				},
				justifyContent: "center",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: { md: "center", xs: "flex-start" },
					width: { md: "60%", sm: "90%", xs: "95%" },
					textAlign: { md: "center", xs: "left" },
				}}
			>
				<Typography
					sx={{
						display: "flex",
						fontSize: "16px",
						fontWeight: "600",
						lineHeight: "24px",
						color: darkMode ? "#BB86FC" : "#0F65C7",
						textAlign: { xs: "left", md: "center" },
						mb: "12px",
					}}
				>
					Features
				</Typography>

				<Typography
					sx={{
						fontSize: { md: "48px", xs: "36px" },
						fontWeight: "600",
						lineHeight: { md: "60px", xs: "44px" },
						letterSpacing: "-2px",
						color: darkMode ? "#FFFFFF" : "#101828",
					}}
				>
					Services that will help you Grow
				</Typography>

				<Typography
					sx={{
						mt: { xs: "16px", md: "20px" },
						fontSize: { md: "20px", xs: "18px" },
						fontWeight: "400",
						lineHeight: { md: "30px", xs: "28px" },
						letterSpacing: "-0.02em",
						color: darkMode ? "#B0B0B0" : "#475467",
					}}
				>
					Powerful, self-serve product and growth analytics to help you convert,
					engage, and retain more users.
				</Typography>
			</Box>
		</Box>
	);
}

export default ServicesHelp;
