import React, { useEffect } from "react";
import EnableDevelopers from "../../Components/EnableDevelopers";
import { Box, Grid } from "@mui/material";
import FirstWaveAi from "../../Components/FirstWaveAi";
import Notified from "../../Components/Notified";
import ColdCalling from "../../Components/ColdCalliing";
import ContactCard from "../../Components/ContactCard";
import ContactForm from "../../Components/ContactForm";
//import AlreadyGrowing from "../../Components/AlreadyGrowing";
import FAQSection from "../../Components/FAQSection";

function ContactUs() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const cardData = [
		{
			cardImg: "../../asets/images/smileIcon.png",
			title: "Call us",
			description: "We’re open 24/7 ",
			link: "+1 (305) 797-9977",
			icon: "../../asets/images/smileIcon.png",
		},
		{
			cardImg: "../../asets/images/smileIcon.png",
			title: "Chat with us",
			description: "Speak to our friendly team",
			link: "sales@firstwave.ai",
			icon: "../../asets/images/smileIcon.png",
		},
		{
			cardImg: "../../asets/images/smileIcon.png",
			title: "Chat with support",
			description: "We’re here to help",
			link: "sales@firstwave.ai",
			icon: "../../asets/images/smileIcon.png",
		},
	];
	const headerData = {
		pageName: "Contact Us",
		title: "We’d love to hear from you",
		description: "Our friendly team is always here to chat.",
	};
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			<EnableDevelopers headerData={headerData} />

			<Box
				sx={{
					mt: { xs: "64px", md: "96px" },
					width: {
						md: "85%",
						xs: "95%",
						sm: "90%",
					},
				}}
			>
				<Grid container spacing={3}>
					{cardData.map((card, index) => {
						return (
							<Grid
								item
								xs={12}
								md={2}
								lg={3}
								mx={"auto"}
								sx={{
									maxWidth: "100% !important",
									minWidth: "30% !important",
								}}
								key={index}
							>
								<ContactCard card={card} />
							</Grid>
						);
					})}
				</Grid>
			</Box>

			{/* <ContactForm /> */}
			<ColdCalling />
			<Notified />
			<FirstWaveAi />
		</Box>
	);
}

export default ContactUs;
