import React, { useContext } from "react";
import { Box, Grid } from "@mui/material";
import SalesWithCheckIcon from "../../Components/SalesWithCheckIcon";
import { ThemeContext } from "../../context/ThemeContext";
import darkImage from "../../asets/images/Content1.png";
import liteImage from "../../asets/images/Content3.png";

export default function SalesHeroSlide({ TextContent }) {
  const { darkMode } = useContext(ThemeContext);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        px: 2,
        mb: 5,
        backgroundColor: darkMode ? "#0C111D" : "#FFFFFF",
        padding: "40px 0",
      }}>
      <Box sx={{ width: "100%", maxWidth: "1200px" }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item md={6} xs={12}>
            <SalesWithCheckIcon TextContent={TextContent} />
          </Grid>
          <Grid item md={6} xs={12}>
            <Box sx={{ position: "relative", textAlign: "center" }}>
              {darkMode ? (
                <img
                  src={darkImage}
                  alt="Sales Hero"
                  style={{
                    width: "80%",
                    borderRadius: "8px",
                    marginBottom: "20px",
                  }}
                />
              ) : (
                <img
                  src={liteImage}
                  alt="Sales Hero"
                  style={{
                    width: "80%",
                    borderRadius: "8px",
                    marginBottom: "20px",
                  }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
