import { Box, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import { ThemeContext } from "../../context/ThemeContext";

function HowPurchase() {
  const { darkMode } = useContext(ThemeContext);

  return (
    <Box
      sx={{
        display: "flex",
        mt: {
          md: "192px",
          xs: "128px",
        },
        justifyContent: "center",
        backgroundColor: darkMode ? "#121212" : "#FFFFFF", // Conditional background color
        padding: "40px 0", // Add padding to top and bottom for better spacing
      }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: { md: "center", xs: "flex-start" },
          width: { md: "60%", sm: "90%", xs: "95%" },
          textAlign: { md: "center", xs: "left" },
        }}>
        <Typography
          sx={{
            display: "flex",
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "24px",
            color: darkMode ? "#90CAF9" : "#0F65C7", // Conditional color
            textAlign: { xs: "left", md: "center" },
            mb: "12px",
          }}>
          Pricing
        </Typography>

        <Typography
          sx={{
            fontSize: { md: "48px", xs: "36px" },
            fontWeight: "600",
            lineHeight: { md: "60px", xs: "44px" },
            letterSpacing: "-2px",
            color: darkMode ? "#E0E0E0" : "#000000", // Conditional color
          }}>
          How do I purchase Sales Hero?
        </Typography>

        <Typography
          sx={{
            mt: { xs: "16px", md: "20px" },
            fontSize: { md: "20px", xs: "18px" },
            fontWeight: "400",
            lineHeight: { md: "30px", xs: "28px" },
            letterSpacing: "-0.02em",
            color: darkMode ? "#B0BEC5" : "#000000", // Conditional color
          }}>
          Sales Hero operates on a pay per campaign model, allowing users to set
          a budget and pay for each campaign they launch, similar to Google Ads
          or Meta Ads.
        </Typography>
      </Box>
    </Box>
  );
}

export default HowPurchase;
