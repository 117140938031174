import React, { useEffect, useState } from "react";
import PricingPageHeader from "../../Components/PricingPageHeader";
import { Box } from "@mui/material";
import ColdCalling from "../../Components/ColdCalliing";
import Notified from "../../Components/Notified";
import FirstWaveAi from "../../Components/FirstWaveAi";
//import HowPurchase from "../../Components/HowParchase";
//import ConsistentlyAuthority from "../../Components/ConsistentlyAuthority";
import Question from "../../Components/Question";
import HeroSales from "../../Sections/HeroSales";
import SalesSidekick from "../../Sections/SalesSidekick";

export default function Pricing() {
	const [activeButton, setActiveButton] = useState("Sales Sidekick");

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<PricingPageHeader
				activeButton={activeButton}
				setActiveButton={setActiveButton}
			/>
			{activeButton && activeButton === "Sales Hero" ? (
				<SalesSidekick />
			) : (
				<HeroSales />
			)}
			<ColdCalling />
			<Notified />
			<FirstWaveAi />
		</Box>
	);
}
