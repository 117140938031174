import React, { useContext } from "react";
import { Button, Card, CardContent, Typography, Box } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ThemeContext } from "../../context/ThemeContext";
import { useNavigate } from "react-router-dom";

const PlanCard = ({ title, price, description, features }) => {
	const navigation = useNavigate();
	const { darkMode } = useContext(ThemeContext);

	return (
		<Card
			sx={{
				padding: "20px",
				borderRadius: "8px",
				boxShadow: darkMode ? "rgba(0, 0, 0, 0.5)" : "rgba(234, 236, 240, 1)",
				border: darkMode
					? "1px solid rgba(50, 50, 50, 1)"
					: "1px solid rgba(234, 236, 240, 1)",
				overflow: "hidden",
				height: "633px",
				backgroundColor: darkMode ? "#333" : "#fff",
				color: darkMode ? "#fff" : "#000",
			}}
		>
			<CardContent>
				<Typography
					component="h2"
					sx={{
						fontWeight: "bold",
						fontSize: "18px",
						lineHeight: "28px",
						textAlign: "left",
					}}
				>
					{title}
				</Typography>
				<Typography
					textAlign="left"
					variant="h3"
					sx={{ mt: "16px", fontWeight: "bold" }}
				>
					{price}
				</Typography>
				<Typography
					textAlign="left"
					component="p"
					sx={{
						mt: "16px",
						fontSize: "16px",
						fontWeight: "400",
						lineHeight: "24px",
						color: darkMode ? "#bbb" : "#666",
						marginBottom: "20px",
					}}
				>
					{description}
				</Typography>
				<Button
					variant="contained"
					color="primary"
					onClick={() => navigation("/signup")}
					sx={{
						mt: "32px",
						width: "100%",
						textTransform: "none",
						fontSize: "16px",
						fontWeight: "600px",
						lineHeight: "24px",
						height: "48px",
						backgroundColor: darkMode ? "#555" : "#1976d2",
						color: darkMode ? "#fff" : "#fff",
					}}
				>
					Get started
				</Button>
				<Typography
					component="p"
					sx={{
						mt: "64px",
						fontSize: "16px",
						fontWeight: "600",
						lineHeight: "24px",
						textAlign: "left",
					}}
				>
					FEATURES
				</Typography>
				<Typography
					textAlign="left"
					component="p"
					sx={{
						mt: "4px",
						fontSize: "16px",
						fontWeight: "400",
						lineHeight: "24px",
					}}
				>
					You can <span style={{ fontWeight: "bold" }}>Start</span> to explore
					from here....
				</Typography>
				<Box
					sx={{
						mt: "24px",
						fontSize: "16px",
						fontWeight: "400",
						lineHeight: "24px",
						textAlign: "left",
					}}
				>
					{features.map((feature, index) => (
						<Box key={index} sx={{ display: "flex", mt: "16px" }}>
							<CheckCircleOutlineIcon
								sx={{
									marginRight: "10px",
									color: darkMode ? "#bbb" : "#1976d2",
								}}
							/>
							<Typography
								sx={{
									fontSize: "16px",
									fontWeight: "400",
									lineHeight: "24px",
									textAlign: "left",
								}}
							>
								{feature}
							</Typography>
						</Box>
					))}
				</Box>
			</CardContent>
		</Card>
	);
};

const PriceCard = ({ plans, headings }) => {
	const { darkMode } = useContext(ThemeContext);

	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 3,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 3,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 2,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};

	return (
		<Box
			sx={{
				mt: { md: "96px", xs: "64px" },
				display: "flex",
				flexFlow: "column",
				alignItems: "center",
				mb: { md: "95px", xs: "64px" },
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: { md: "center", xs: "flex-start" },
					width: { md: "60%", sm: "90%", xs: "95%" },
					textAlign: { md: "center", xs: "left" },
				}}
			>
				<Typography
					sx={{
						display: "flex",
						fontSize: "16px",
						fontWeight: "600",
						lineHeight: "24px",
						color: darkMode ? "#0F65C7" : "#0F65C7",
						textAlign: { xs: "left", md: "center" },
						mb: "12px",
					}}
				>
					{headings.title}
				</Typography>

				<Typography
					sx={{
						fontSize: { md: "48px", xs: "36px" },
						fontWeight: "600",
						lineHeight: { md: "60px", xs: "44px" },
						letterSpacing: "-2px",
						color: darkMode ? "#ffffff" : "#101828",
					}}
				>
					{headings.heading}
				</Typography>

				<Typography
					sx={{
						mt: { xs: "16px", md: "20px" },
						fontSize: { md: "20px", xs: "18px" },
						fontWeight: "400",
						lineHeight: { md: "30px", xs: "28px" },
						letterSpacing: "-0.02em",
						color: darkMode ? "#B0B0B0" : "#475467",
					}}
				>
					{headings.description}
				</Typography>
			</Box>
			<img
				style={{
					marginTop: "37px",
					width: "152px",
					height: "54px",
					display: "flex",
					textAlign: "center",
				}}
				src={require("../../asets/images/mostPopular.png")}
				alt="Most Popular"
			/>
			<Box
				sx={{
					width: { xs: "95%", sm: "90%", md: "85%" },
					mt: "40px",
				}}
			>
				<Carousel
					responsive={responsive}
					infinite={true}
					autoPlay={false}
					containerClass="carousel-container"
					itemClass="carousel-item"
				>
					{plans.map((plan, index) => (
						<Box
							key={index}
							sx={{
								display: "flex",
								justifyContent: "center",
								padding: "10px",
							}}
						>
							<PlanCard
								title={plan.title}
								price={plan.price}
								description={plan.description}
								features={plan.features}
							/>
						</Box>
					))}
				</Carousel>
			</Box>
		</Box>
	);
};

export default PriceCard;
