import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { motion } from "framer-motion";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import TvIcon from "@mui/icons-material/Tv";
import RedeemOutlinedIcon from "@mui/icons-material/RedeemOutlined";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import { ThemeContext } from "../../context/ThemeContext";

function CustomerJourneysSales({ journeysForSales }) {
    const [selectedOption, setSelectedOption] = useState("");
    const { darkMode } = useContext(ThemeContext);

    const handleClick = (option) => {
        setSelectedOption(option);
        console.log("handleClick =>", option);
    };

    return (
        <Box
            sx={{
                width: "95%",
                mt: "96px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
            }}
        >
            <Box
                sx={{
                    width: {
                        md: "85%",
                        xs: "95%",
                        sm: "90%",
                    },
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={5}>
                        <Box
                            component={motion.div}
                            initial={{ opacity: 0, x: -50 }}
                            whileInView={{ opacity: 1, x: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.5 }}
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: { md: "flex-start", xs: "center" },
                                maxWidth: "400px",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: { md: "30px", xs: "24px" },
                                    fontWeight: { md: "600", xs: "700" },
                                    lineHeight: { xs: "36px", md: "36px" },
                                    letterSpacing: "-0.25px",
                                    color: darkMode ? "#2F67CA" : "#101828",
                                }}
                            >
                                {journeysForSales.heading}
                            </Typography>
                            <Typography
                                sx={{
                                    mt: { md: "20px", xs: "16px" },
                                    fontSize: { md: "18px", xs: "18px" },
                                    fontWeight: "400",
                                    lineHeight: { md: "28px", xs: "24px" },
                                    letterSpacing: "-0.25px",
                                    mb: "64px",
                                    color: darkMode ? "white" : "#475467",
                                }}
                            >
                                {journeysForSales.description}
                            </Typography>
                            {[
                                "Setting Up FAQs and Q&As",
                                "Updating FAQs and Q&As",
                                "Handling Unknown Questions",
                                "Redirecting Calls to Human Agents",
                                "Analyzing Call Performance",
                                "Integrating Customer Sidekick with CRM",
                                "Managing Multiple Locations",
                            ].map((option, index) => (
                                <Button
                                    key={index}
                                    component={motion.button}
                                    whileHover={{ scale: 1.05 }}
                                    sx={{
                                        textTransform: "none",
                                        height: "60px",
                                        width: "100%",
                                        fontSize: "18px",
                                        fontWeight: "600",
                                        lineHeight: "28px",
                                        display: "flex",
                                        textAlign: "start",
                                        marginTop: "10px",
                                        backgroundColor:
                                            selectedOption === option
                                                ? "#1976d2"
                                                : "inherit",
                                        color:
                                            selectedOption === option
                                                ? "#FFFFFF"
                                                : "#475467",
                                        "&:hover": {
                                            backgroundColor: "#1976d2",
                                        },
                                        justifyContent: "flex-start", // Align text to the left
                                        marginLeft: "10px", // Adjust this value to increase/decrease indentation
                                    }}
                                    onClick={() => handleClick(option)}
                                >
                                    {option}
                                </Button>
                            ))}
                        </Box>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        lg={7}
                        sx={{
                            minHeight: {
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center", // Ensures content is centered horizontally
                                minHeight: "700px",
                            },
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column", // Ensures vertical stacking of children
                                justifyContent: "center",
                                alignItems: "center", // Center align the children horizontally
                                backgroundColor: darkMode
                                    ? "#041932"
                                    : "#E9F1FC",
                                borderRadius: "8px",
                                padding: { xs: "32px 0px" },
                                overflow: "hidden",
                                flexWrap: "wrap",
                                width: "100%", // Full width to use all available space
                                maxWidth: "80%", // Limits maximum width to 80% for larger screens
                                mt: 2, // Adds margin top for spacing
                                mb: 2, // Adds margin bottom for spacing
                            }}
                        >
                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    minHeight: "700px",
                                    display: "flex",
                                    flexWrap: "wrap",
                                    position: "relative",
                                }}
                            >
                                {selectedOption === "" && (
                                    <Grid item xs={6} md={8} sm={7}>
                                        <Button
                                            sx={{
                                                ml: {
                                                    xs: "10px",
                                                    sm: "30px",
                                                    md: "50px",
                                                },
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                backgroundColor: "#FFFFFF",
                                                width: {
                                                    sm: "100%",
                                                    xs: "250px",
                                                },
                                                textTransform: "none",
                                                borderRadius: "80px",
                                                marginBottom: "24px",
                                                boxShadow: 1,
                                            }}
                                        >
                                            {/*<IconButton 
                        sx={{
                          color: "#E3EFFD",
                          m: { xs: "5px", sm: "16px", md: "16px" },
                        }}>*/}
                                            <PeopleAltOutlinedIcon
                                                sx={{ color: "#7F56D9" }}
                                            />
                                            {/* </IconButton> */}
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: "14px",
                                                        sm: "16px",
                                                    },
                                                    fontWeight: {
                                                        xs: "450",
                                                        sm: "600",
                                                    },
                                                    color: "#7F56D9",
                                                }}
                                            >
                                                Sign Up and Login
                                            </Typography>
                                        </Button>
                                        <Button
                                            sx={{
                                                ml: {
                                                    xs: "10px",
                                                    sm: "30px",
                                                    md: "50px",
                                                },
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                backgroundColor: "#FFFFFF",
                                                width: {
                                                    sm: "100%",
                                                    xs: "250px",
                                                },
                                                textTransform: "none",
                                                borderRadius: "80px",
                                                marginBottom: "24px",
                                                boxShadow: 1,
                                            }}
                                        >
                                            <IconButton
                                                sx={{
                                                    color: "#E3EFFD",
                                                    m: {
                                                        xs: "5px",
                                                        sm: "16px",
                                                        md: "16px",
                                                    },
                                                }}
                                            >
                                                <TvIcon
                                                    sx={{ color: "#C37E5E" }}
                                                />
                                            </IconButton>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: "14px",
                                                        sm: "16px",
                                                    },
                                                    fontWeight: {
                                                        xs: "450",
                                                        sm: "600",
                                                    },
                                                    color: "#C37E5E",
                                                }}
                                            >
                                                Dashboard Navigation
                                            </Typography>
                                        </Button>
                                        <Button
                                            sx={{
                                                ml: {
                                                    xs: "10px",
                                                    sm: "30px",
                                                    md: "50px",
                                                },
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                backgroundColor: "#FFFFFF",
                                                width: {
                                                    sm: "100%",
                                                    xs: "250px",
                                                },
                                                textTransform: "none",
                                                borderRadius: "80px",
                                                marginBottom: "24px",
                                                boxShadow: 1,
                                            }}
                                        >
                                            <IconButton
                                                sx={{
                                                    color: "#E3EFFD",
                                                    m: {
                                                        xs: "5px",
                                                        sm: "16px",
                                                        md: "16px",
                                                    },
                                                }}
                                            >
                                                <RedeemOutlinedIcon
                                                    sx={{ color: "#3D9060" }}
                                                />
                                            </IconButton>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: "14px",
                                                        sm: "16px",
                                                    },
                                                    fontWeight: {
                                                        xs: "450",
                                                        sm: "600",
                                                    },
                                                    color: "#3D9060",
                                                }}
                                            >
                                                Budget Selection
                                            </Typography>
                                        </Button>
                                        <Button
                                            sx={{
                                                ml: {
                                                    xs: "10px",
                                                    sm: "30px",
                                                    md: "50px",
                                                },
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                backgroundColor: "#FFFFFF",
                                                width: {
                                                    sm: "100%",
                                                    xs: "250px",
                                                },
                                                textTransform: "none",
                                                borderRadius: "80px",
                                                marginBottom: "24px",
                                                boxShadow: 1,
                                            }}
                                        >
                                            <IconButton
                                                sx={{
                                                    color: "#E3EFFD",
                                                    m: {
                                                        xs: "5px",
                                                        sm: "16px",
                                                        md: "16px",
                                                    },
                                                }}
                                            >
                                                <RedeemOutlinedIcon
                                                    sx={{ color: "#2F67CA" }}
                                                />
                                            </IconButton>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: "14px",
                                                        sm: "16px",
                                                    },
                                                    fontWeight: {
                                                        xs: "450",
                                                        sm: "600",
                                                    },
                                                    color: "#2F67CA",
                                                }}
                                            >
                                                Language Customization
                                            </Typography>
                                        </Button>
                                        <Button
                                            sx={{
                                                ml: {
                                                    xs: "10px",
                                                    sm: "30px",
                                                    md: "50px",
                                                },
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                backgroundColor: "#FFFFFF",
                                                width: {
                                                    sm: "100%",
                                                    xs: "250px",
                                                },
                                                textTransform: "none",
                                                borderRadius: "80px",
                                                marginBottom: "24px",
                                                boxShadow: 1,
                                            }}
                                        >
                                            <IconButton
                                                sx={{
                                                    color: "#E3EFFD",
                                                    m: {
                                                        xs: "5px",
                                                        sm: "16px",
                                                        md: "16px",
                                                    },
                                                }}
                                            >
                                                <SettingsApplicationsIcon
                                                    sx={{ color: "#D14949" }}
                                                />
                                            </IconButton>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        xs: "14px",
                                                        sm: "16px",
                                                    },
                                                    fontWeight: {
                                                        xs: "450",
                                                        sm: "600",
                                                    },
                                                    color: "#D14949",
                                                }}
                                            >
                                                A/B Testing Setup
                                            </Typography>
                                        </Button>
                                    </Grid>
                                )}
                                {selectedOption ===
                                    "Updating FAQs and Q&As" && (
                                    <Grid item xs={6} md={8} sm={7}>
                                        <motion.div
                                            initial={{ opacity: 0, y: -20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{
                                                duration: 0.5,
                                                delay: 1 * 0.1,
                                            }}
                                        >
                                            <Button
                                                sx={{
                                                    ml: {
                                                        xs: "10px",
                                                        sm: "30px",
                                                        md: "50px",
                                                    },
                                                    display: "flex",
                                                    justifyContent:
                                                        "flex-start",
                                                    backgroundColor: "#FFFFFF",
                                                    width: {
                                                        sm: "100%",
                                                        xs: "250px",
                                                    },
                                                    textTransform: "none",
                                                    borderRadius: "80px",
                                                    marginBottom: "24px",
                                                    boxShadow: 1,
                                                }}
                                            >
                                                <IconButton
                                                    sx={{
                                                        color: "#E3EFFD",
                                                        m: {
                                                            xs: "5px",
                                                            sm: "16px",
                                                            md: "16px",
                                                        },
                                                    }}
                                                >
                                                    <PeopleAltOutlinedIcon
                                                        sx={{
                                                            color: "#7F56D9",
                                                        }}
                                                    />
                                                </IconButton>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            xs: "14px",
                                                            sm: "16px",
                                                        },
                                                        fontWeight: {
                                                            xs: "450",
                                                            sm: "600",
                                                        },
                                                        color: "#7F56D9",
                                                    }}
                                                >
                                                    Sign Up and Login
                                                </Typography>
                                            </Button>
                                            <Button
                                                sx={{
                                                    ml: {
                                                        xs: "10px",
                                                        sm: "30px",
                                                        md: "50px",
                                                    },
                                                    display: "flex",
                                                    justifyContent:
                                                        "flex-start",
                                                    backgroundColor: "#FFFFFF",
                                                    width: {
                                                        sm: "100%",
                                                        xs: "250px",
                                                    },
                                                    textTransform: "none",
                                                    borderRadius: "80px",
                                                    marginBottom: "24px",
                                                    boxShadow: 1,
                                                }}
                                            >
                                                <IconButton
                                                    sx={{
                                                        color: "#E3EFFD",
                                                        m: {
                                                            xs: "5px",
                                                            sm: "16px",
                                                            md: "16px",
                                                        },
                                                    }}
                                                >
                                                    <TvIcon
                                                        sx={{
                                                            color: "#C37E5E",
                                                        }}
                                                    />
                                                </IconButton>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            xs: "14px",
                                                            sm: "16px",
                                                        },
                                                        fontWeight: {
                                                            xs: "450",
                                                            sm: "600",
                                                        },
                                                        color: "#C37E5E",
                                                    }}
                                                >
                                                    Dashboard Navigation
                                                </Typography>
                                            </Button>
                                            <Button
                                                sx={{
                                                    ml: {
                                                        xs: "10px",
                                                        sm: "30px",
                                                        md: "50px",
                                                    },
                                                    display: "flex",
                                                    justifyContent:
                                                        "flex-start",
                                                    backgroundColor: "#FFFFFF",
                                                    width: {
                                                        sm: "100%",
                                                        xs: "250px",
                                                    },
                                                    textTransform: "none",
                                                    borderRadius: "80px",
                                                    marginBottom: "24px",
                                                    boxShadow: 1,
                                                }}
                                            >
                                                <IconButton
                                                    sx={{
                                                        color: "#E3EFFD",
                                                        m: {
                                                            xs: "5px",
                                                            sm: "16px",
                                                            md: "16px",
                                                        },
                                                    }}
                                                >
                                                    <RedeemOutlinedIcon
                                                        sx={{
                                                            color: "#3D9060",
                                                        }}
                                                    />
                                                </IconButton>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            xs: "14px",
                                                            sm: "16px",
                                                        },
                                                        fontWeight: {
                                                            xs: "450",
                                                            sm: "600",
                                                        },
                                                        color: "#3D9060",
                                                    }}
                                                >
                                                    Unknown Question Log
                                                </Typography>
                                            </Button>
                                            <Button
                                                sx={{
                                                    ml: {
                                                        xs: "10px",
                                                        sm: "30px",
                                                        md: "50px",
                                                    },
                                                    display: "flex",
                                                    justifyContent:
                                                        "flex-start",
                                                    backgroundColor: "#FFFFFF",
                                                    width: {
                                                        sm: "100%",
                                                        xs: "250px",
                                                    },
                                                    textTransform: "none",
                                                    borderRadius: "80px",
                                                    marginBottom: "24px",
                                                    boxShadow: 1,
                                                }}
                                            >
                                                <IconButton
                                                    sx={{
                                                        color: "#E3EFFD",
                                                        m: {
                                                            xs: "5px",
                                                            sm: "16px",
                                                            md: "16px",
                                                        },
                                                    }}
                                                >
                                                    <RedeemOutlinedIcon
                                                        sx={{
                                                            color: "#2F67CA",
                                                        }}
                                                    />
                                                </IconButton>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            xs: "14px",
                                                            sm: "16px",
                                                        },
                                                        fontWeight: {
                                                            xs: "450",
                                                            sm: "600",
                                                        },
                                                        color: "#2F67CA",
                                                    }}
                                                >
                                                    Update FAQs
                                                </Typography>
                                            </Button>
                                        </motion.div>
                                    </Grid>
                                )}
                                {selectedOption ===
                                    "Setting Up FAQs and Q&As" && (
                                    <Grid item xs={6} md={8} sm={7}>
                                        <motion.div
                                            initial={{ opacity: 0, y: -20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{
                                                duration: 0.5,
                                                delay: 2 * 0.1,
                                            }}
                                        >
                                            <Button
                                                sx={{
                                                    ml: {
                                                        xs: "10px",
                                                        sm: "30px",
                                                        md: "50px",
                                                    },
                                                    display: "flex",
                                                    justifyContent:
                                                        "flex-start",
                                                    backgroundColor: "#FFFFFF",
                                                    width: {
                                                        sm: "100%",
                                                        xs: "250px",
                                                    },
                                                    textTransform: "none",
                                                    borderRadius: "80px",
                                                    marginBottom: "24px",
                                                    boxShadow: 1,
                                                }}
                                            >
                                                <IconButton
                                                    sx={{
                                                        color: "#E3EFFD",
                                                        m: {
                                                            xs: "5px",
                                                            sm: "16px",
                                                            md: "16px",
                                                        },
                                                    }}
                                                >
                                                    <PeopleAltOutlinedIcon
                                                        sx={{
                                                            color: "#7F56D9",
                                                        }}
                                                    />
                                                </IconButton>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            xs: "14px",
                                                            sm: "16px",
                                                        },
                                                        fontWeight: {
                                                            xs: "450",
                                                            sm: "600",
                                                        },
                                                        color: "#7F56D9",
                                                    }}
                                                >
                                                    Save and Publish
                                                </Typography>
                                            </Button>
                                            <Button
                                                sx={{
                                                    ml: {
                                                        xs: "10px",
                                                        sm: "30px",
                                                        md: "50px",
                                                    },
                                                    display: "flex",
                                                    justifyContent:
                                                        "flex-start",
                                                    backgroundColor: "#FFFFFF",
                                                    width: {
                                                        sm: "100%",
                                                        xs: "250px",
                                                    },
                                                    textTransform: "none",
                                                    borderRadius: "80px",
                                                    marginBottom: "24px",
                                                    boxShadow: 1,
                                                }}
                                            >
                                                <IconButton
                                                    sx={{
                                                        color: "#E3EFFD",
                                                        m: {
                                                            xs: "5px",
                                                            sm: "16px",
                                                            md: "16px",
                                                        },
                                                    }}
                                                >
                                                    <TvIcon
                                                        sx={{
                                                            color: "#C37E5E",
                                                        }}
                                                    />
                                                </IconButton>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            xs: "14px",
                                                            sm: "16px",
                                                        },
                                                        fontWeight: {
                                                            xs: "450",
                                                            sm: "600",
                                                        },
                                                        color: "#C37E5E",
                                                    }}
                                                >
                                                    Call Handling
                                                </Typography>
                                            </Button>
                                        </motion.div>
                                    </Grid>
                                )}
                                {selectedOption ===
                                    "Handling Unknown Questions" && (
                                    <Grid item xs={6} md={8} sm={7}>
                                        <motion.div
                                            initial={{ opacity: 0, y: -20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{
                                                duration: 0.5,
                                                delay: 2 * 0.1,
                                            }}
                                        >
                                            <Button
                                                sx={{
                                                    ml: {
                                                        xs: "10px",
                                                        sm: "30px",
                                                        md: "50px",
                                                    },
                                                    display: "flex",
                                                    justifyContent:
                                                        "flex-start",
                                                    backgroundColor: "#FFFFFF",
                                                    width: {
                                                        sm: "100%",
                                                        xs: "250px",
                                                    },
                                                    textTransform: "none",
                                                    borderRadius: "80px",
                                                    marginBottom: "24px",
                                                    boxShadow: 1,
                                                }}
                                            >
                                                <IconButton
                                                    sx={{
                                                        color: "#E3EFFD",
                                                        m: {
                                                            xs: "5px",
                                                            sm: "16px",
                                                            md: "16px",
                                                        },
                                                    }}
                                                >
                                                    <PeopleAltOutlinedIcon
                                                        sx={{
                                                            color: "#7F56D9",
                                                        }}
                                                    />
                                                </IconButton>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            xs: "14px",
                                                            sm: "16px",
                                                        },
                                                        fontWeight: {
                                                            xs: "450",
                                                            sm: "600",
                                                        },
                                                        color: "#7F56D9",
                                                    }}
                                                >
                                                    Save and Publish
                                                </Typography>
                                            </Button>
                                            <Button
                                                sx={{
                                                    ml: {
                                                        xs: "10px",
                                                        sm: "30px",
                                                        md: "50px",
                                                    },
                                                    display: "flex",
                                                    justifyContent:
                                                        "flex-start",
                                                    backgroundColor: "#FFFFFF",
                                                    width: {
                                                        sm: "100%",
                                                        xs: "250px",
                                                    },
                                                    textTransform: "none",
                                                    borderRadius: "80px",
                                                    marginBottom: "24px",
                                                    boxShadow: 1,
                                                }}
                                            >
                                                <IconButton
                                                    sx={{
                                                        color: "#E3EFFD",
                                                        m: {
                                                            xs: "5px",
                                                            sm: "16px",
                                                            md: "16px",
                                                        },
                                                    }}
                                                >
                                                    <TvIcon
                                                        sx={{
                                                            color: "#C37E5E",
                                                        }}
                                                    />
                                                </IconButton>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            xs: "14px",
                                                            sm: "16px",
                                                        },
                                                        fontWeight: {
                                                            xs: "450",
                                                            sm: "600",
                                                        },
                                                        color: "#C37E5E",
                                                    }}
                                                >
                                                    Call Handling
                                                </Typography>
                                            </Button>
                                        </motion.div>
                                    </Grid>
                                )}

                                {selectedOption ===
                                    "Redirecting Calls to Human Agents" && (
                                    <Grid item xs={6} md={8} sm={7}>
                                        <motion.div
                                            initial={{ opacity: 0, y: -20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{
                                                duration: 0.5,
                                                delay: 2 * 0.1,
                                            }}
                                        >
                                            <Button
                                                sx={{
                                                    ml: {
                                                        xs: "10px",
                                                        sm: "30px",
                                                        md: "50px",
                                                    },
                                                    display: "flex",
                                                    justifyContent:
                                                        "flex-start",
                                                    backgroundColor: "#FFFFFF",
                                                    width: {
                                                        sm: "100%",
                                                        xs: "250px",
                                                    },
                                                    textTransform: "none",
                                                    borderRadius: "80px",
                                                    marginBottom: "24px",
                                                    boxShadow: 1,
                                                }}
                                            >
                                                <IconButton
                                                    sx={{
                                                        color: "#E3EFFD",
                                                        m: {
                                                            xs: "5px",
                                                            sm: "16px",
                                                            md: "16px",
                                                        },
                                                    }}
                                                >
                                                    <PeopleAltOutlinedIcon
                                                        sx={{
                                                            color: "#7F56D9",
                                                        }}
                                                    />
                                                </IconButton>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            xs: "14px",
                                                            sm: "16px",
                                                        },
                                                        fontWeight: {
                                                            xs: "450",
                                                            sm: "600",
                                                        },
                                                        color: "#7F56D9",
                                                    }}
                                                >
                                                    Logging and notification
                                                </Typography>
                                            </Button>
                                            <Button
                                                sx={{
                                                    ml: {
                                                        xs: "10px",
                                                        sm: "30px",
                                                        md: "50px",
                                                    },
                                                    display: "flex",
                                                    justifyContent:
                                                        "flex-start",
                                                    backgroundColor: "#FFFFFF",
                                                    width: {
                                                        sm: "100%",
                                                        xs: "250px",
                                                    },
                                                    textTransform: "none",
                                                    borderRadius: "80px",
                                                    marginBottom: "24px",
                                                    boxShadow: 1,
                                                }}
                                            >
                                                <IconButton
                                                    sx={{
                                                        color: "#E3EFFD",
                                                        m: {
                                                            xs: "5px",
                                                            sm: "16px",
                                                            md: "16px",
                                                        },
                                                    }}
                                                >
                                                    <TvIcon
                                                        sx={{
                                                            color: "#C37E5E",
                                                        }}
                                                    />
                                                </IconButton>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            xs: "14px",
                                                            sm: "16px",
                                                        },
                                                        fontWeight: {
                                                            xs: "450",
                                                            sm: "600",
                                                        },
                                                        color: "#C37E5E",
                                                    }}
                                                >
                                                    Human Agent Enagage
                                                </Typography>
                                            </Button>
                                        </motion.div>
                                    </Grid>
                                )}
                                {selectedOption ===
                                    "Analyzing Call Performance" && (
                                    <Grid item xs={6} md={8} sm={7}>
                                        <motion.div
                                            initial={{ opacity: 0, y: -20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{
                                                duration: 0.5,
                                                delay: 2 * 0.1,
                                            }}
                                        >
                                            <Button
                                                sx={{
                                                    ml: {
                                                        xs: "10px",
                                                        sm: "30px",
                                                        md: "50px",
                                                    },
                                                    display: "flex",
                                                    justifyContent:
                                                        "flex-start",
                                                    backgroundColor: "#FFFFFF",
                                                    width: {
                                                        sm: "100%",
                                                        xs: "250px",
                                                    },
                                                    textTransform: "none",
                                                    borderRadius: "80px",
                                                    marginBottom: "24px",
                                                    boxShadow: 1,
                                                }}
                                            >
                                                <IconButton
                                                    sx={{
                                                        color: "#E3EFFD",
                                                        m: {
                                                            xs: "5px",
                                                            sm: "16px",
                                                            md: "16px",
                                                        },
                                                    }}
                                                >
                                                    <PeopleAltOutlinedIcon
                                                        sx={{
                                                            color: "#7F56D9",
                                                        }}
                                                    />
                                                </IconButton>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            xs: "14px",
                                                            sm: "16px",
                                                        },
                                                        fontWeight: {
                                                            xs: "450",
                                                            sm: "600",
                                                        },
                                                        color: "#7F56D9",
                                                    }}
                                                >
                                                    Update FAQs
                                                </Typography>
                                            </Button>
                                            <Button
                                                sx={{
                                                    ml: {
                                                        xs: "10px",
                                                        sm: "30px",
                                                        md: "50px",
                                                    },
                                                    display: "flex",
                                                    justifyContent:
                                                        "flex-start",
                                                    backgroundColor: "#FFFFFF",
                                                    width: {
                                                        sm: "100%",
                                                        xs: "250px",
                                                    },
                                                    textTransform: "none",
                                                    borderRadius: "80px",
                                                    marginBottom: "24px",
                                                    boxShadow: 1,
                                                }}
                                            >
                                                <IconButton
                                                    sx={{
                                                        color: "#E3EFFD",
                                                        m: {
                                                            xs: "5px",
                                                            sm: "16px",
                                                            md: "16px",
                                                        },
                                                    }}
                                                >
                                                    <TvIcon
                                                        sx={{
                                                            color: "#C37E5E",
                                                        }}
                                                    />
                                                </IconButton>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            xs: "14px",
                                                            sm: "16px",
                                                        },
                                                        fontWeight: {
                                                            xs: "450",
                                                            sm: "600",
                                                        },
                                                        color: "#C37E5E",
                                                    }}
                                                >
                                                    Continuous improvement
                                                </Typography>
                                            </Button>
                                        </motion.div>
                                    </Grid>
                                )}
                                {selectedOption ===
                                    "Integrating Customer Sidekick with CRM" && (
                                    <Grid item xs={6} md={8} sm={7}>
                                        <motion.div
                                            initial={{ opacity: 0, y: -20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{
                                                duration: 0.5,
                                                delay: 2 * 0.1,
                                            }}
                                        >
                                            <Button
                                                sx={{
                                                    ml: {
                                                        xs: "10px",
                                                        sm: "30px",
                                                        md: "50px",
                                                    },
                                                    display: "flex",
                                                    justifyContent:
                                                        "flex-start",
                                                    backgroundColor: "#FFFFFF",
                                                    width: {
                                                        sm: "100%",
                                                        xs: "250px",
                                                    },
                                                    textTransform: "none",
                                                    borderRadius: "80px",
                                                    marginBottom: "24px",
                                                    boxShadow: 1,
                                                }}
                                            >
                                                <IconButton
                                                    sx={{
                                                        color: "#E3EFFD",
                                                        m: {
                                                            xs: "5px",
                                                            sm: "16px",
                                                            md: "16px",
                                                        },
                                                    }}
                                                >
                                                    <PeopleAltOutlinedIcon
                                                        sx={{
                                                            color: "#7F56D9",
                                                        }}
                                                    />
                                                </IconButton>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            xs: "14px",
                                                            sm: "16px",
                                                        },
                                                        fontWeight: {
                                                            xs: "450",
                                                            sm: "600",
                                                        },
                                                        color: "#7F56D9",
                                                    }}
                                                >
                                                    Metrics and Reporting
                                                </Typography>
                                            </Button>
                                            <Button
                                                sx={{
                                                    ml: {
                                                        xs: "10px",
                                                        sm: "30px",
                                                        md: "50px",
                                                    },
                                                    display: "flex",
                                                    justifyContent:
                                                        "flex-start",
                                                    backgroundColor: "#FFFFFF",
                                                    width: {
                                                        sm: "100%",
                                                        xs: "250px",
                                                    },
                                                    textTransform: "none",
                                                    borderRadius: "80px",
                                                    marginBottom: "24px",
                                                    boxShadow: 1,
                                                }}
                                            >
                                                <IconButton
                                                    sx={{
                                                        color: "#E3EFFD",
                                                        m: {
                                                            xs: "5px",
                                                            sm: "16px",
                                                            md: "16px",
                                                        },
                                                    }}
                                                >
                                                    <TvIcon
                                                        sx={{
                                                            color: "#C37E5E",
                                                        }}
                                                    />
                                                </IconButton>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            xs: "14px",
                                                            sm: "16px",
                                                        },
                                                        fontWeight: {
                                                            xs: "450",
                                                            sm: "600",
                                                        },
                                                        color: "#C37E5E",
                                                    }}
                                                >
                                                    Location specific update
                                                </Typography>
                                            </Button>
                                        </motion.div>
                                    </Grid>
                                )}
                                {selectedOption ===
                                    "Managing Multiple Locations" && (
                                    <Grid item xs={6} md={8} sm={7}>
                                        <motion.div
                                            initial={{ opacity: 0, y: -20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{
                                                duration: 0.5,
                                                delay: 2 * 0.1,
                                            }}
                                        >
                                            <Button
                                                sx={{
                                                    ml: {
                                                        xs: "10px",
                                                        sm: "30px",
                                                        md: "50px",
                                                    },
                                                    display: "flex",
                                                    justifyContent:
                                                        "flex-start",
                                                    backgroundColor: "#FFFFFF",
                                                    width: {
                                                        sm: "100%",
                                                        xs: "250px",
                                                    },
                                                    textTransform: "none",
                                                    borderRadius: "80px",
                                                    marginBottom: "24px",
                                                    boxShadow: 1,
                                                }}
                                            >
                                                <IconButton
                                                    sx={{
                                                        color: "#E3EFFD",
                                                        m: {
                                                            xs: "5px",
                                                            sm: "16px",
                                                            md: "16px",
                                                        },
                                                    }}
                                                >
                                                    <PeopleAltOutlinedIcon
                                                        sx={{
                                                            color: "#7F56D9",
                                                        }}
                                                    />
                                                </IconButton>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            xs: "14px",
                                                            sm: "16px",
                                                        },
                                                        fontWeight: {
                                                            xs: "450",
                                                            sm: "600",
                                                        },
                                                        color: "#7F56D9",
                                                    }}
                                                >
                                                    Metrics and Reporting
                                                </Typography>
                                            </Button>
                                            <Button
                                                sx={{
                                                    ml: {
                                                        xs: "10px",
                                                        sm: "30px",
                                                        md: "50px",
                                                    },
                                                    display: "flex",
                                                    justifyContent:
                                                        "flex-start",
                                                    backgroundColor: "#FFFFFF",
                                                    width: {
                                                        sm: "100%",
                                                        xs: "250px",
                                                    },
                                                    textTransform: "none",
                                                    borderRadius: "80px",
                                                    marginBottom: "24px",
                                                    boxShadow: 1,
                                                }}
                                            >
                                                <IconButton
                                                    sx={{
                                                        color: "#E3EFFD",
                                                        m: {
                                                            xs: "5px",
                                                            sm: "16px",
                                                            md: "16px",
                                                        },
                                                    }}
                                                >
                                                    <TvIcon
                                                        sx={{
                                                            color: "#C37E5E",
                                                        }}
                                                    />
                                                </IconButton>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            xs: "14px",
                                                            sm: "16px",
                                                        },
                                                        fontWeight: {
                                                            xs: "450",
                                                            sm: "600",
                                                        },
                                                        color: "#C37E5E",
                                                    }}
                                                >
                                                    Location specific update
                                                </Typography>
                                            </Button>
                                        </motion.div>
                                    </Grid>
                                )}
                                <Grid
                                    item
                                    xs={6}
                                    md={4}
                                    sm={5}
                                    sx={{
                                        display: "flex",
                                        overflow: "hidden",
                                        flexWrap: "wrap",
                                        alignItems: "center",
                                        position: {
                                            md: "absolute",
                                            xs: "relative",
                                        },
                                        right: { md: "-70px", xs: "-15%" },
                                        bottom: { md: "-15%", xs: "-170px" },
                                        width: "100%",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: { xs: "200px", md: "350px" },
                                            objectFit: "cover",
                                            display: "flex",
                                            flexWrap: "wrap",
                                            overflow: "hidden",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <img
                                            src={journeysForSales.pic}
                                            alt="Robot"
                                            style={{
                                                objectFit: "cover",
                                                minHeight: "300px",
                                                width: "100%",
                                                objectPosition: "bottom",
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default CustomerJourneysSales;
