import { Box, Typography, TextField, InputAdornment } from "@mui/material";
import React, { useContext, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { ThemeContext } from "../../context/ThemeContext";

export default function HeaderWithSearch({ headerData }) {
  const { darkMode } = useContext(ThemeContext);

  useEffect(() => {
    console.log("theme ...>", darkMode ? "dark" : "light");
  }, [darkMode]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: darkMode ? "#041932" : "#E3EFFD",
        paddingTop: { md: "190px", xs: "100px" },
        paddingBottom: { md: "110px", xs: "64px" },
      }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "left", md: "center" },
          textAlign: { xs: "left", md: "center" },
          width: { md: "54%", lg: "54%", xl: "54%", sm: "90%", xs: "95%" },
        }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "24px",
            color: darkMode ? "#0F65C7" : "#0F65C7",
            mb: "12px",
          }}>
          {headerData.pageName}
        </Typography>
        <Typography
          sx={{
            fontSize: { md: "52px", xs: "36px" },
            fontWeight: { md: "800", xs: "600" },
            lineHeight: { md: "62px", xs: "44px" },
            color: darkMode ? "white" : "black",
          }}>
          {headerData.title}
        </Typography>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "30px",
            color: darkMode ? "#B0B0B0" : "#666666",
            mt: "16px",
            letterSpacing: "-0.25px",
          }}>
          {headerData.description}
        </Typography>
        <TextField
          placeholder="Search"
          variant="outlined"
          size="small"
          onChange={(e) => {
            headerData.setSearchValue(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  style={{ color: darkMode ? "#BB86FC" : "#667085" }}
                />
              </InputAdornment>
            ),
            style: {
              height: "40px",
              borderRadius: "8px",
              borderColor: darkMode ? "#BB86FC" : "#dfe1e5",
              padding: "0 12px",
              backgroundColor: darkMode ? "#333333" : "#ffffff",
              color: darkMode ? "white" : "black",
            },
          }}
          style={{
            borderRadius: "8px",
            borderColor: darkMode ? "#BB86FC" : "#dfe1e5",
            width: "300px",
            marginTop: "60px",
            backgroundColor: darkMode ? "#333333" : "#ffffff",
            color: darkMode ? "white" : "black",
          }}
        />
      </Box>
    </Box>
  );
}
