import React, { useContext } from "react";
import { Typography, Box, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../context/ThemeContext";

const HomeHeader = () => {
  const { darkMode } = useContext(ThemeContext);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/contact-us");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh", // Set to 100vh to ensure it covers the full viewport height
        alignItems: "center",
        width: "100%",
        pt: { md: "152px", xs: "100px" },
        pb: { md: "66px", xs: "50px" },
        backgroundColor: darkMode ? "#0C111D" : "#E3EFFD",
        color: darkMode ? "#ffffff" : "#000000",
      }}>
      <Box
        sx={{
          display: "flex",
          width: "100%", // Ensure the width is 100% for the inner content
          justifyContent: "center", // Center content horizontally
          px: { sm: "5%", xs: "3%" }, // Add padding for spacing from the edges
        }}>
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: { md: "60px", xs: "40px" },
                fontWeight: 700,
                lineHeight: { md: "72px", xs: "48px" },
                letterSpacing: "-0.02em",
                textAlign: "left",
                animation: "fadeIn 2s ease-in-out",
              }}>
              <span style={{ color: darkMode ? "#F5F5F6" : "#0F65C7" }}>
                First Wave AI’s{" "}
              </span>
              <span style={{ color: darkMode ? "#0F65C7" : "#101828" }}>
                phone assistants will transform your business
              </span>
            </Typography>
            <Typography
              sx={{
                mt: "24px",
                textAlign: "left",
                fontSize: { md: "20px", xs: "18px" },
                lineHeight: { md: "30px", xs: "28px" },
                animation: "fadeIn 3s ease-in-out",
              }}>
              Boost your sales performance and customer satisfaction with our
              innovative AI solutions.
            </Typography>
            <Button
              onClick={handleNavigate}
              variant="contained"
              sx={{
                mt: "48px",
                textTransform: "none",
                border: "solid 1px",
                borderColor: darkMode ? "#0F65C7" : "#0F65C7",
                borderRadius: "12px",
                fontWeight: "600",
                fontSize: "16px",
                width: { md: "160px", xs: "100%" },
                backgroundColor: darkMode ? "#0F65C7" : "#0F65C7",
                color: "#ffffff",
                animation: "fadeIn 4s ease-in-out",
              }}>
              Contact Sales
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <img
                style={{
                  width: "70%",
                  height: "auto",
                  maxWidth: "400px",
                  maxHeight: "400px",
                  objectFit: "contain",
                }}
                alt="Header Robot"
                src={require(darkMode
                  ? "../../asets/images/headerRobot.png"
                  : "../../asets/images/himage2.png")}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default HomeHeader;
