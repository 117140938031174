import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const FirstWaveAi = () => {
	const navigate = useNavigate();

	const handleNavigate = (path) => {
		navigate(path);
	};

	return (
		<Box
			sx={{
				width: "100%",
				display: "flex",
				justifyContent: "center",
				mt: {
					md: "64px",
					xs: "50px",
				},
				mb: "64px",
			}}
		>
			<Box
				sx={{
					margin: "5px auto",
					width: { xs: "95%", sm: "90%", md: "85%", lg: "85%", xl: "85%" },
				}}
			>
				<Grid
					container
					spacing={3}
					sx={{
						width: "100%",
					}}
				>
					<Grid item xs={12} md={4} lg={3} xl={3}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								width: "100%",
							}}
						>
							<Typography
								sx={{
									fontSize: "18px",
									fontWeight: "700",
									lineHeight: "28px",
								}}
							>
								First Wave AI
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={6} md={4} lg={2} xl={2}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								width: "100%",
							}}
						>
							<Typography
								onClick={() => handleNavigate("/")}
								sx={{
									fontSize: "14px",
									fontWeight: "600",
									lineHeight: "20px",
									color: "rgba(102, 112, 133, 1)",
								}}
							>
								Home
							</Typography>
							<Typography
								onClick={() => handleNavigate("/about-us")}
								sx={{
									fontSize: "16px",
									fontWeight: "600",
									lineHeight: "24px",
									textAlign: "left",
									mt: "16px",
									cursor: "pointer",
								}}
							>
								About us
							</Typography>
							<Typography
								onClick={() => handleNavigate("/Product")}
								sx={{
									fontSize: "16px",
									fontWeight: "600",
									lineHeight: "24px",
									textAlign: "left",
									mt: "12px",
									cursor: "pointer",
								}}
							>
								Products
							</Typography>
							{/* <Typography
								onClick={() => handleNavigate("/resources")}
								sx={{
									fontSize: "16px",
									fontWeight: "600",
									lineHeight: "24px",
									textAlign: "left",
									mt: "16px",
									cursor: "pointer",
								}}
							>
								Resources
							</Typography> */}
							<Typography
								onClick={() => handleNavigate("/price")}
								sx={{
									fontSize: "16px",
									fontWeight: "600",
									lineHeight: "24px",
									textAlign: "left",
									mt: "16px",
									cursor: "pointer",
								}}
							>
								Pricing
							</Typography>
							<Typography
								onClick={() => handleNavigate("/contact-us")}
								sx={{
									fontSize: "16px",
									fontWeight: "600",
									lineHeight: "24px",
									textAlign: "left",
									mt: "16px",
									cursor: "pointer",
								}}
							>
								Contact
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={6} md={4} lg={2} xl={2}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								width: "100%",
							}}
						>
							<Typography
								sx={{
									color: "rgba(102, 112, 133, 1)",
									fontSize: "14px",
									fontWeight: "600",
									lineHeight: "20px",
								}}
							>
								Products
							</Typography>
							<Typography
								onClick={() => handleNavigate("/product-sales-hero")}
								sx={{
									fontSize: "16px",
									fontWeight: "600",
									lineHeight: "24px",
									textAlign: "left",
									mt: "16px",
									cursor: "pointer",
								}}
							>
								Sales Hero
							</Typography>
							<Typography
								onClick={() => handleNavigate("/product-customer-sidekick")}
								sx={{
									fontSize: "16px",
									fontWeight: "600",
									lineHeight: "24px",
									textAlign: "left",
									mt: "12px",
									cursor: "pointer",
								}}
							>
								Sales Sidekick
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={6} md={4} lg={2} xl={2}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								width: "100%",
							}}
						>
							<Typography
								sx={{
									color: "rgba(102, 112, 133, 1)",
									fontSize: "14px",
									fontWeight: "600",
									lineHeight: "20px",
								}}
							>
								Resources
							</Typography>
							{/* <Typography
                onClick={() => handleNavigate("/blog")}
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "24px",
                  textAlign: "left",
                  mt: "16px",
                  cursor: "pointer",
                }}>
                Blog
              </Typography> */}
							{/* <Typography
                onClick={() => handleNavigate("/case-studies")}
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "24px",
                  textAlign: "left",
                  mt: "12px",
                  cursor: "pointer",
                }}>
                Case Studies
              </Typography> */}
							{/* <Typography
                onClick={() => handleNavigate("/tutorials&guides")}
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  lineHeight: "24px",
                  textAlign: "left",
                  mt: "12px",
                  cursor: "pointer",
                }}>
                Video Tutorials
              </Typography> */}
							{/* <Typography
								onClick={() => handleNavigate("/documentation")}
								sx={{
									fontSize: "16px",
									fontWeight: "600",
									lineHeight: "24px",
									textAlign: "left",
									mt: "12px",
									cursor: "pointer",
								}}
							>
								Documentation
							</Typography> */}
							<Typography
								onClick={() => handleNavigate("/faqs")}
								sx={{
									fontSize: "16px",
									fontWeight: "600",
									lineHeight: "24px",
									textAlign: "left",
									mt: "12px",
									cursor: "pointer",
								}}
							>
								FAQ’s
							</Typography>
						</Box>
					</Grid>
					{/* <Grid item xs={6} md={4} lg={2} xl={2}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								width: "100%",
							}}
						>
							<Typography
								sx={{
									color: "rgba(102, 112, 133, 1)",
									fontSize: "14px",
									fontWeight: "600",
									lineHeight: "20px",
								}}
							>
								Social
							</Typography>
							<Typography
								sx={{
									fontSize: "16px",
									fontWeight: "600",
									lineHeight: "24px",
									textAlign: "left",
									mt: "16px",
									cursor: "pointer",
								}}
							>
								Twitter
							</Typography>
							<Typography
								sx={{
									fontSize: "16px",
									fontWeight: "600",
									lineHeight: "24px",
									textAlign: "left",
									mt: "12px",
									cursor: "pointer",
								}}
							>
								LinkedIn
							</Typography>
							<Typography
								sx={{
									fontSize: "16px",
									fontWeight: "600",
									lineHeight: "24px",
									textAlign: "left",
									mt: "12px",
									cursor: "pointer",
								}}
							>
								Facebook
							</Typography>
							<Typography
								sx={{
									fontSize: "16px",
									fontWeight: "600",
									lineHeight: "24px",
									textAlign: "left",
									mt: "12px",
									cursor: "pointer",
								}}
							>
								GitHub
							</Typography>
						</Box>
					</Grid> */}
				</Grid>
			</Box>
		</Box>
	);
};

export default FirstWaveAi;
