import { Box, Typography } from "@mui/material";
import React from "react";

function ContactCard({ card }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F9FAFB",
        p: "24px",
        borderRadius: "8px",
        height: "100%",
        justifyContent: "space-between",
      }}>
      <Box sx={{ mb: "auto" }}>
        <img
          width="48px"
          height="48px"
          src={require("../../asets/images/smileIcon.png")}
          alt=""
        />
        <Typography
          sx={{
            mt: "64px",
            color: "#101828",
            fontSize: "20px",
            fontWeight: "600",
            lineHeight: "30px",
            textAlign: "left",
          }}>
          {card.title}
        </Typography>
        <Typography
          sx={{
            color: "#475467",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "24px",
            textAlign: "left",
            mt: "8px",
          }}>
          {card.description}
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: "600",
          lineHeight: "24px",
          textAlign: "left",
          color: "#0F65C7",
          mt: "20px",
        }}>
        {card.link}
      </Typography>
    </Box>
  );
}

export default ContactCard;
