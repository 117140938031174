import React, { useContext } from "react";
import {
	Box,
	Container,
	Typography,
	//Card,
	//CardContent,
	//Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	//Grid,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RemoveIcon from "@mui/icons-material/Remove";
import { ThemeContext } from "../../context/ThemeContext";
//import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const features = [
	{
		name: "Secure managed Environment",
		free: true,
		basic: true,
		advanced: true,
		professional: true,
	},
	{
		name: "Call Forwarding",
		free: true,
		basic: true,
		advanced: true,
		professional: true,
	},
	{
		name: "Pre-Identified Q&As",
		free: true,
		basic: true,
		advanced: true,
		professional: true,
	},
	{
		name: "Customization for Q&A",
		free: false,
		basic: "Basic",
		advanced: "More",
		professional: "Full",
	},
	{
		name: "Monthly Performance Reports",
		free: false,
		basic: true,
		advanced: true,
		professional: true,
	},
	{
		name: "Enhanced Support",
		free: false,
		basic: false,
		advanced: true,
		professional: true,
	},
];

const pricing = [
	{ title: "Free", price: "$0", description: "Free Features" },
	{ title: "Basic", price: "$200", description: "Basic Features" },
	{ title: "Advanced", price: "$700", description: "Advanced Features" },
	{
		title: "Professional",
		price: "$1000",
		description: "Professional Features",
	},
];

// const responsive = {
// 	superLargeDesktop: {
// 		// the naming can be any, depends on you.
// 		breakpoint: { max: 4000, min: 3000 },
// 		items: 5,
// 	},
// 	desktop: {
// 		breakpoint: { max: 3000, min: 1024 },
// 		items: 3,
// 	},
// 	tablet: {
// 		breakpoint: { max: 1024, min: 464 },
// 		items: 2,
// 	},
// 	mobile: {
// 		breakpoint: { max: 464, min: 0 },
// 		items: 1,
// 	},
// };

const PriceTable = () => {
	const { darkMode } = useContext(ThemeContext);

	return (
		<Container sx={{ maxWidth: "100%", px: 0 }}>
			<Box sx={{ mt: 4, mb: 4 }}>
				<Typography
					variant="h4"
					align="center"
					gutterBottom
					sx={{ color: darkMode ? "#ffffff" : "#333" }}
				>
					Compare our plans and find yours
				</Typography>
				<Typography
					variant="subtitle1"
					align="center"
					sx={{ color: darkMode ? "#bbbbbb" : "#666", mb: 2 }}
				>
					Simple, transparent pricing that grows with you.
				</Typography>

				{/* <Carousel
					responsive={responsive}
					ssr
					infinite
					autoPlay
					autoPlaySpeed={3000}
				>
					{pricing.map((plan, index) => (
						<Grid item xs={12} key={index}>
							<Card
								sx={{
									minHeight: 360,
									display: "flex",
									flexDirection: "column",
									justifyContent: "space-between",
									backgroundColor: darkMode ? "#424242" : "#fff",
								}}
							>
								<CardContent>
									<Typography
										variant="h6"
										gutterBottom
										sx={{ color: darkMode ? "#ffffff" : "#333" }}
									>
										{plan.title}
									</Typography>
									<Typography
										variant="h3"
										sx={{ color: darkMode ? "#ffffff" : "#000" }}
									>
										{plan.price}
									</Typography>
									<Typography
										variant="body2"
										sx={{ color: darkMode ? "#cccccc" : "#777" }}
									>
										{plan.description}
									</Typography>
								</CardContent>
								<Box sx={{ mx: 2, mb: 2 }}>
									<Button
										variant="contained"
										fullWidth
										sx={{
											textTransform: "none",
											backgroundColor: darkMode ? "#0d47a1" : undefined,
										}}
									>
										Get started
									</Button>
								</Box>
							</Card>
						</Grid>
					))}
				</Carousel> */}

				<TableContainer
					component={Paper}
					sx={{ mt: 4, backgroundColor: darkMode ? "#333" : "#fff" }}
				>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Feature</TableCell>
								{pricing.map((plan) => (
									<TableCell key={plan.title} align="center">
										{plan.title}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{features.map((feature, index) => (
								<TableRow key={index}>
									<TableCell
										component="th"
										scope="row"
										sx={{ color: darkMode ? "#ffffff" : "#333" }}
									>
										{feature.name}
									</TableCell>
									{pricing.map((plan) => (
										<TableCell key={plan.title} align="center">
											{typeof feature[plan.title.toLowerCase()] ===
											"boolean" ? (
												feature[plan.title.toLowerCase()] ? (
													<CheckCircleOutlineIcon
														sx={{ color: darkMode ? "#4caf50" : "#4caf50" }}
													/>
												) : (
													<RemoveIcon
														sx={{ color: darkMode ? "#f44336" : "#f44336" }}
													/>
												)
											) : (
												<Typography
													sx={{ color: darkMode ? "#ffffff" : "#000" }}
												>
													{feature[plan.title.toLowerCase()]}
												</Typography>
											)}
										</TableCell>
									))}
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</Container>
	);
};

export default PriceTable;
